import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {NewsItem} from '@app/shared/model/vigilance/news-item';
import {NewsStoreService} from '@app/vigilance/store/news-store.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(600)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(600, style({opacity: 0})))
        ])
    ]
})
export class NewsComponent implements OnInit {
    news$: Observable<NewsItem[]>;
    loading$: Observable<boolean>;

    constructor(private nss: NewsStoreService) {
    }

    ngOnInit(): void {
        this.news$ = this.nss.observableValue$;
        this.loading$ = this.nss.loading$;
    }

}
