import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable} from 'rxjs';
import {NewsLetter} from '@app/shared/model/vigilance/news-letter';
import {NewsLetterStoreService} from '@app/vigilance/store/news-letter-store.service';

@Component({
    selector: 'app-news-letters',
    templateUrl: './news-letters.component.html',
    styleUrls: ['./news-letters.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(600)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(600, style({opacity: 0})))
        ])
    ]
})
export class NewsLettersComponent implements OnInit {
    newsletters$: Observable<NewsLetter[]>;
    loading$: Observable<boolean>;

    constructor(private nlss: NewsLetterStoreService) {
    }

    ngOnInit(): void {
        this.newsletters$ = this.nlss.observableValue$;
        this.loading$ = this.nlss.loading$;
    }

}
