<div class="container-fluid">
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="(whoweare$ | async).tech.length > 0">
        <div class="col-12">
            <h1 class="text-muted">Responsables de la Tecnología</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mt-2" *ngFor="let a of (whoweare$|async).tech" [@simpleFadeAnimation]="'in'">
            <div class="media border border-warning rounded p-1">
                <img src="assets/img/contacts/{{a.image}}" class="mr-3" alt="..." style="height: 100px">
                <div class="media-body">
                    <h5 class="mt-0">{{a.degree}} {{a.name}}</h5>
                    <span>{{a.email}}</span><br>
                    <span>{{a.desc1}}</span><br>
                    <span>{{a.desc2}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="(whoweare$ | async).content.length > 0">
        <div class="col-12">
            <h1 class="text-muted">Responsables de los contenidos</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mt-2" *ngFor="let a of (whoweare$|async).content" [@simpleFadeAnimation]="'in'">
            <div class="media border border-warning rounded p-1">
                <img src="assets/img/contacts/{{a.image}}" class="mr-3" alt="..." style="height: 100px">
                <div class="media-body">
                    <h5 class="mt-0">{{a.degree}} {{a.name}}</h5>
                    <span>{{a.email}}</span><br>
                    <span>{{a.desc1}}</span><br>
                    <span>{{a.desc2}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="(whoweare$ | async).aknowledgements.length > 0">
        <div class="col-12">
            <h1 class="text-muted">Agradecimientos</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mt-2" *ngFor="let a of (whoweare$|async).aknowledgements" [@simpleFadeAnimation]="'in'">
            <div class="media border border-warning rounded p-1">
                <img src="assets/img/contacts/{{a.image}}" class="mr-3" alt="..." style="width: 100px">
                <div class="media-body">
                    <h5 class="mt-0">{{a.degree}} {{a.name}}</h5>
                    <span>{{a.email}}</span><br>
                    <span>{{a.desc1}}</span><br>
                    <span>{{a.desc2}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

