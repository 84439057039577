<div class="container">
    <div class="row mt-2" *ngIf="(loading$ | async) === true">
        <div class="col-12 text-center">
            <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-1" >
            <button type="button" (click)="goBack()" class="btn btn-outline-info"><i class="fa fa-angle-double-left" aria-hidden="true"></i> regresar</button>
        </div>
        <div class="col-6 col-md-5">
            <ng-container *ngFor="let o of (patent$ | async).offices">
                <strong *ngIf="o ==='USPTO Grant'">United States Patent</strong>
                <strong *ngIf="o ==='USPTO App'">United States Patent Application</strong>
            </ng-container>
        </div>
        <div class="col-6 text-right">
            <ng-container *ngFor="let o of (patent$ | async).offices">
                <a *ngIf="o ==='USPTO Grant'" target="_blank" href="http://patft.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&d=PALL&p=1&u=%2Fnetahtml%2FPTO%2Fsrchnum.htm&r=1&f=G&l=50&s1={{(patent$ | async).PN}}.PN.&OS=PN/&RS=PN/{{(patent$ | async).PN}}">{{(patent$ | async).CC}}{{(patent$ | async).PN}}{{(patent$ | async).KD}}</a>
                <a *ngIf="o ==='USPTO App'" target="_blank" href="http://appft.uspto.gov/netacgi/nph-Parser?Sect1=PTO1&Sect2=HITOFF&d=PG01&p=1&u=%2Fnetahtml%2FPTO%2Fsrchnum.html&r=1&f=G&l=50&s1=%22{{(patent$ | async).PN}}%22.PGNR.&OS=DN/{{(patent$ | async).PN}}&RS=DN/{{(patent$ | async).PN}}">{{(patent$ | async).CC}}{{(patent$ | async).PN}}{{(patent$ | async).KD}}</a>
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-6"><strong><span>{{(patent$ | async).Inventors[0].IN}}</span> <span *ngIf="(patent$ | async).Inventors.length > 1">, et al.</span></strong></div>
        <div class="col-6 text-right">{{(patent$ | async).ISD}}</div>
    </div>
    <div class="row">
        <div class="col-12 text-right" *ngIf="(loading$ | async) === false">
            <share-buttons [theme]="'modern-dark'"
                           [include]="['telegram','linkedin','twitter','email','print','copy']"
                           [show]="9"
                           [showText]="true"
                           [size]="-4"
                           [title]="(patent$|async).TTL"
                           [description]="(patent$|async).ABST"
            ></share-buttons>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h4>{{(patent$ | async).TTL}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <strong>Abstract</strong>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-justify">
            {{(patent$ | async).ABST}}
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-1">
            inventors:
        </div>
        <div class="col-11" *ngIf="(user$|async).username !== ''">
            <ng-container *ngFor="let inventor of (patent$ | async).Inventors">
                <a href="#" routerLink="/metasearcher/techprofile/inventor/{{inventor.id}}">{{inventor.IN}}</a> ({{inventor.ICN}}, <img src="assets/img/Flags/{{inventor.ICN}}.PNG" alt="">)
            </ng-container>
        </div>
        <div class="col-11" *ngIf="(user$|async).username == ''">
            <ng-container *ngFor="let inventor of (patent$ | async).Inventors">
               {{inventor.IN}} ({{inventor.ICN}}, <img src="assets/img/Flags/{{inventor.ICN}}.PNG" alt="">)
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-1">
            Assignees:
        </div>
        <div class="col-11" *ngIf="(user$|async).username !== ''">
            <ng-container *ngFor="let assignee of (patent$ | async).Assignees">
                <a href="#" routerLink="/metasearcher/techprofile/assignee/{{assignee.id}}">{{assignee.AN}}</a> ({{assignee.ACN}}, <img src="assets/img/Flags/{{assignee.ACN}}.PNG" alt="">)
            </ng-container>
        </div>
        <div class="col-11" *ngIf="(user$|async).username == ''">
            <ng-container *ngFor="let assignee of (patent$ | async).Assignees">
                {{assignee.AN}} ({{assignee.ACN}}, <img src="assets/img/Flags/{{assignee.ACN}}.PNG" alt="">)
            </ng-container>
        </div>
    </div>
    <div class="row">
        <div class="col-1">
            Appl. No:
        </div>
        <div class="col-11">
            <span><b>{{(patent$ | async).APN}}</b></span>
        </div>
    </div>
    <div class="row">
        <div class="col-1">
            Filed:
        </div>
        <div class="col-11">
            <span><b>{{(patent$ | async).APD}}</b></span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <strong>Current International Class:</strong>
        </div>
        <div class="col-9 text-right">
            <span *ngFor="let ipc of (patent$ | async).IPCs">
                {{ipc}}&nbsp;&nbsp;&nbsp;
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-3">
            <strong>Current CPC Class:</strong>
        </div>
        <div class="col-9 text-right">
            <span *ngFor="let cpc of (patent$ | async).CPCs">
                {{cpc}}&nbsp;&nbsp;&nbsp;
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <hr>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <strong>Claims</strong>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-justify">
            <p *ngFor="let p of (patent$ | async).ACLM; let idx = index;">
                {{(idx + 1) + '. ' + p}}
            </p>
        </div>
    </div>
</div>


