<div class="container">
    <div class="row mt-2">
        <div class="col-12 col-lg-6">
            <div class="row mt-2">
                <div class="col-12">
                    <h3>Artículos <a href="/api/myfolder/exportArticles"><span class="text-success"><i class="fas fa-download"></i></span></a></h3>
                </div>
                <div class="col-12" *ngIf="(articleFolder$|async).length == 0">
                    Vacía
                </div>
                <div class="col-12 mt-1" *ngFor="let e of (articleFolder$|async)">
                    <span (click)="removeArticle(e)"><i style="cursor: pointer" class="fas fa-trash text-danger" aria-hidden="true"></i>&nbsp;</span>
                    <a href="#" routerLink="//metasearcher/article/{{e.id}}">{{e.year}}-{{e.month}} {{e.ttl}}</a><br><strong>Revista:</strong> {{e.jttl}}<br>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row mt-2">
                <div class="col-12">
                    <h3>Patentes <a href="/api/myfolder/exportPatents"><span class="text-success"><i class="fas fa-download"></i></span></a></h3>
                </div>
                <div class="col-12" *ngIf="(patentFolder$|async).length == 0">
                    Vacía
                </div>
                <div class="col-12 mt-1" *ngFor="let e of (patentFolder$|async)">
                    <span (click)="removePatent(e)"><i style="cursor: pointer" class="fas fa-trash text-danger" aria-hidden="true"></i>&nbsp;</span>
                    <a href="#" routerLink="//metasearcher/patent/{{e.id}}">{{e.cc}}{{e.pn}}{{e.kd}} - {{e.isd}} {{e.ttl}}</a>
                </div>
            </div>
        </div>
    </div>
</div>
