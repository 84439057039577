import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EncodeUriService {

    constructor() {
    }

    static encodeUriComponent(str): string {
        return encodeURIComponent(str).replace(/[!'()*]/g, (c) => '%' + c.charCodeAt(0).toString(16));
    }
}
