import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoreRoutingModule} from './core-routing.module';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {ChartModule} from 'angular2-chartjs';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ShareButtonsModule} from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../../environments/environment';

import {MainComponent} from '@app/core/main/main.component';
import {FooterComponent} from './footer/footer.component';
import {MainMenuComponent} from '@app/core/main-menu/main-menu.component';
import {NotFoundComponent} from '@app/core/not-found/not-found.component';
import {AboutComponent} from '@app/about/about/about.component';
import {FolderComponent} from '@app/folder/folder/folder.component';
import {HelpComponent} from '@app/help/help/help.component';
import {HomeComponent} from '@app/home/home/home.component';
import {ArticleViewComponent} from '@app/meta-searcher/article-view/article-view.component';
import {MetaSearcherComponent} from '@app/meta-searcher/meta-searcher/meta-searcher.component';
import {PatentViewComponent} from '@app/meta-searcher/patent-view/patent-view.component';
import {SciProfileViewComponent} from '@app/meta-searcher/sci-profile-view/sci-profile-view.component';
import {TechProfileViewComponent} from '@app/meta-searcher/tech-profile-view/tech-profile-view.component';
import {CPCLegendComponent} from '@app/metrics/cpc-legend/cpc-legend.component';
import {FrequencyComponent} from '@app/metrics/frequency/frequency.component';
import {IPCLegendComponent} from '@app/metrics/ipc-legend/ipc-legend.component';
import {MapComponent} from '@app/metrics/map/map.component';
import {MetricsComponent} from '@app/metrics/metrics/metrics.component';
import {ArticlesComponent} from '@app/vigilance/articles/articles.component';
import {NewsComponent} from '@app/vigilance/news/news.component';
import {NewsLettersComponent} from '@app/vigilance/news-letters/news-letters.component';
import {PatentsComponent} from '@app/vigilance/patents/patents.component';
import {StatisticsComponent} from '@app/vigilance/statistics/statistics.component';
import {VigilanceComponent} from '@app/vigilance/vigilance/vigilance.component';
import {WhoWeAreComponent} from '@app/who-we-are/who-we-are/who-we-are.component';
import {SharedModule} from '@app/shared/shared.module';

@NgModule({
    declarations: [FooterComponent,
        MainComponent,
        MainMenuComponent,
        NotFoundComponent,
        AboutComponent,
        FolderComponent,
        HelpComponent,
        HomeComponent,
        ArticleViewComponent,
        MetaSearcherComponent,
        PatentViewComponent,
        SciProfileViewComponent,
        TechProfileViewComponent,
        CPCLegendComponent,
        FrequencyComponent,
        IPCLegendComponent,
        MapComponent,
        MetricsComponent,
        ArticlesComponent,
        NewsComponent,
        NewsLettersComponent,
        PatentsComponent,
        StatisticsComponent,
        VigilanceComponent,
        WhoWeAreComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        CoreRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        SharedModule,
        ChartModule,
        ToastrModule.forRoot(),
        NgbModule,
        ShareButtonsModule,
        ShareIconsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}) // ToastrModule added
    ],
    bootstrap: [MainComponent]
})
export class CoreModule {
}
