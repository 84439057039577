<div class="container">
    <div class="row mt-2">
        <div class="d-none d-sm-flex align-items-end justify-content-end col-1 m-0 p-0">
            <img src="assets/img/{{(home$ | async).metasearcher.image}}" alt="" style="height: 38px">
        </div>
        <div class="col-12 col-sm-11">
            <form class="" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="indexname">Tipo de documento:</label>
                        <select class="form-control" formControlName="indexname" id="indexname">
                            <option value="sciIndex">Artículos</option>
                            <option value="techIndex">Patentes</option>
                        </select>
                    </div>
                    <div class="form-group col-md-6">
                        <label for="subject">Tema:</label>
                        <select class="form-control"
                                formControlName="subject"
                                id="subject">
                            <option *ngFor="let d of (subjects$ | async)" value="{{d}}">{{d}}</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12 col-md-6">
                        <label for="subject">Autor:</label>
                        <input autocomplete="off"
                               class="form-control"
                               formControlName="authorFilter"
                               id="authorFilter"
                               placeholder="buscar por autores"
                               type="text">
                    </div>
                    <div class="form-group col-12 col-md-6">
                        <label for="subject">Año:</label>
                        <input autocomplete="off"
                               class="form-control"
                               formControlName="yearFilter"
                               id="yearFilter"
                               placeholder="buscar por año"
                               type="text">
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-12 col-sm-9 col-md-10 mt-1">
                        <input autocomplete="off"
                               class="form-control"
                               formControlName="filter"
                               id="filter"
                               placeholder="buscar por cualquier campo"
                               style="width: 100%;"
                               type="text">
                    </div>
                    <div class="col-12 col-sm-3 col-md-2 mt-1">
                        <button class="btn btn-outline-success col-12" type="submit"
                                [disabled]="searchForm.invalid || (loading$ | async) === true">Buscar
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-sm-11 offset-sm-1 text-muted small">
            Use un <em>espacio en blanco</em> o <em>and</em> para el operador lógico <strong>AND</strong>.
            Use <em>or</em> para el operador lógico <strong>OR</strong><br>
            Ej: término1 (término2 OR término3)
        </div>
    </div>

    <div class="row" *ngIf="(searchResult$ | async).data.length > 0">
        <div class="col-12">
            Encontrados: {{(searchResult$ | async).total_found}} registros en {{(searchResult$ | async).time}}s.
        </div>
    </div>
    <div class="row" *ngFor="let e of (searchResult$|async).data" (scroll)="onWindowScroll()">
        <div class="col-12" *ngIf="e.hasOwnProperty('pn')">
            <span (click)="removePatent(e)" *ngIf="e.inUserFolder == true && haveSessionValue()"><i style="cursor: pointer" class="fas fa-check-square text-success" aria-hidden="true"></i>&nbsp;</span>
            <span (click)="addPatent(e)" *ngIf="e.inUserFolder == false  && haveSessionValue()"><i  style="cursor: pointer" class="far fa-square text-success" aria-hidden="true"></i>&nbsp;</span>
            <a href="#" routerLink="//metasearcher/patent/{{e.id}}">{{e.cc}}{{e.pn}}{{e.kd}} - {{e.isd}} {{e.ttl}}</a>
        </div>
        <div class="col-12 mt-2" *ngIf="e.hasOwnProperty('jttl')">
            <span (click)="removeArticle(e)" *ngIf="e.inUserFolder == true && haveSessionValue()"><i style="cursor: pointer" class="fas fa-check-square text-success" aria-hidden="true"></i>&nbsp;</span>
            <span (click)="addArticle(e)" *ngIf="e.inUserFolder == false  && haveSessionValue()"><i  style="cursor: pointer" class="far fa-square text-success" aria-hidden="true"></i>&nbsp;</span>
            <a href="#" routerLink="//metasearcher/article/{{e.id}}">{{e.year}}-{{e.month}} {{e.ttl}}</a><br><strong>Revista:</strong> {{e.jttl}}<br>
        </div>
    </div>
    <div class="row my-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                 role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</div>
