<div class="container">
    <div class="row mt-2">
        <div class="col-12">
            <div class="card shadow">
                <div class="card-header bg-info text-light">
                    Panel de Control
                </div>
                <div class="card-body">
                    <form [formGroup]="mapForm" (ngSubmit)="onSubmit()">
                        <div class="form-row align-items-center">
                            <div class="form-group col-12 col-md-4">
                                <label for="indcode">Indicador:&nbsp;</label>
                                <select name="indcode" id="indcode" class="form-control"
                                        formControlName="indcode">
                                    <option value=""></option>
                                    <option value="ART_ANAN">Autores conjuntos</option>
                                    <option value="ART_AANAAN">Filiaciones conjuntas</option>
                                    <ng-container *ngIf="(user$|async).username !==''">
                                        <option value="ART_KWRDKWRD">Palabras clave conjuntas</option>
                                        <option value="ART_MeSHMeSH">MeSH conjuntos</option>
                                    </ng-container>
                                    <option value="ART_ANAAN">Autores y filiaciones</option>
                                    <option value="ART_JTTLAN">Autores y revistas</option>
                                    <option value="ART_SUBJAN">Autores y materia</option>
                                    <ng-container *ngIf="(user$|async).username !==''">
                                        <option value="ART_KWRDAN">Autores y palabras clave</option>
                                        <option value="ART_MeSHAN">Autores y MeSH</option>
                                        <option value="ART_CWTTL">CoWord en Títulos</option>
                                        <option value="ART_CWABST">CoWord en Resumenes</option>
                                        <option value="ART_CWTTLABST">CoWord en Títulos y Resumenes</option>
                                    </ng-container>
                                    <hr>
                                    <option value="PAT_ANAN">Titulares conjuntos</option>
                                    <option value="PAT_ININ">Inventores conjuntos</option>
                                    <option value="PAT_CIPCIP">CIP conjunta</option>
                                    <option value="PAT_CCPCCP">CCP conjunta</option>
                                    <ng-container *ngIf="(user$|async).username !==''">
                                        <option value="PAT_ACNACN">Países[Titulares] conjuntos</option>
                                        <option value="PAT_ICNICN">Países[Inventores] conjuntos</option>
                                    </ng-container>
                                    <option value="PAT_ANIN">Titulares e inventores</option>
                                    <option value="PAT_ANACN">Titulares y países</option>
                                    <ng-container *ngIf="(user$|async).username !==''">
                                        <option value="PAT_INICN">Inventores y países</option>
                                    </ng-container>
                                    <option value="PAT_ANIPC">Titulares y CIP</option>
                                    <ng-container *ngIf="(user$|async).username !==''">
                                        <option value="PAT_INIPC">Inventores y CIP</option>
                                        <option value="PAT_ANCPC">Titulares y CCP</option>
                                        <option value="PAT_INCPC">Inventores y CCP</option>
                                        <option value="PAT_CWTTL">CoWord en Títulos</option>
                                        <option value="PAT_CWABST">CoWord en Resumenes</option>
                                        <option value="PAT_CWTTLABST">CoWord en Títulos y Resumenes</option>
                                    </ng-container>
                                </select>
                            </div>
                            <div class="form-group col-12 col-md-2">
                                <label for="yearMin">&nbsp;Entre los años:&nbsp;{{mapForm.value.yearMin}}</label>
                                <input id="yearMin" type="range" min="2000" max="{{mapForm.value.yearMax}}" step="1"
                                       class="form-control" formControlName="yearMin">
                            </div>
                            <div class="form-group col-12 col-md-2">
                                <label for="yearMax">&nbsp;y:&nbsp;{{mapForm.value.yearMax}}</label>
                                <input id="yearMax" type="range" min="{{mapForm.value.yearMin}}" max="{{currentYear}}" step="1"
                                       class="form-control" formControlName="yearMax">
                            </div>
                            <div class="form-group col-12 col-md-2" placement="top" ngbTooltip="Usa solo documentos del top selecionado">
                                <label for="top">Top 10:&nbsp;</label>
                                <select name="top" id="top" class="form-control"
                                        formControlName="top">
                                    <option value=""></option>
                                    <option value="author">Autores</option>
                                    <option value="colleague">Colegas</option>
                                    <option value="keyword">Palabras Clave</option>
                                    <option value="MeSH">MeSH</option>
                                </select>
                            </div>
                            <div class="form-group col-12 col-md-2">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Usa solo los documentos de la última actualización">
                                    <input class="form-check-input" name="isLast" type="checkbox" id="isLast" formControlName="isLast" (click)="onChangeisLast()">
                                    <label class="form-check-label" for="isLast">
                                        Últimos Documentos
                                    </label>
                                </div>
                                <ng-container *ngIf="mapForm.value.isLast == true" >
                                    <div class="row mt-2" style="position: relative">
                                        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
                                            <div *ngIf="(loadingMetadata$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                                                 role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col small"><strong>Artículos:</strong> {{(metadata$ | async).last_articles}}</div>
                                    <div class="col small"><strong>Patentes:</strong> {{(metadata$ | async).last_patents}}</div>
                                    <div class="col small" *ngFor="let u of (metadata$ | async).article_last_updates"><strong>{{u.name}}:</strong> {{u.date}}</div>
                                    <div class="col small" *ngFor="let u of (metadata$ | async).patent_last_updates"><strong>{{u.name}}:</strong> {{u.date}}</div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="subject">Tema:</label>
                                <select class="form-control"
                                        formControlName="subject"
                                        id="subject">
                                    <option *ngFor="let d of (subjects$ | async)" value="{{d}}">{{d}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="filter">Filtro:</label>
                                <input id="filter" type="text" class="form-control" formControlName="filter">
                            </div>
                        </div>
                        <div class="form-row align-items-center">
                            <div class="form-group col-12 col-md-2" placement="top" ngbTooltip="Controla la fuerza de atracción de los nodos">
                                <label for="gravity">Gravedad:&nbsp;{{mapForm.value.gravity}}</label>
                                <input id="gravity" type="range" min="0.1" max="2" step="0.1"
                                       class="form-control" formControlName="gravity">
                            </div>
                            <div class="form-group col-12 col-md-2" placement="top" ngbTooltip="Controla la fuerza de repulsión de los nodos">
                                <label for="charge">Carga:&nbsp;{{mapForm.value.charge}}</label>
                                <input id="charge" type="range" min="500" max="2000" step="100"
                                       class="form-control" formControlName="charge">
                            </div>
                            <div class="form-group col-12 col-md-2" placement="top" ngbTooltip="Limita la cantidad de nodos en el mapa (Nodo: elemento de una lista enlazada de un grafo)">
                                <label for="limitNodes">Límite #Nodos:&nbsp;{{mapForm.value.limitNodes}}</label>
                                <input id="limitNodes" type="range" min="20" max="100" step="1"
                                       class="form-control" formControlName="limitNodes">
                            </div>
                            <div class="form-group col-12 col-md-2" placement="top" ngbTooltip="Controla el tamaño de la tipografía en el mapa">
                                <label for="fontSize">Tamaño Fuente:&nbsp;{{mapForm.value.fontSize}}</label>
                                <input id="fontSize" type="range" min="8" max="20" step="1"
                                       class="form-control" formControlName="fontSize">
                            </div>
                        </div>
                        <div class="form-row align-items-center">
                            <div class="form-group col-12 col-md-2">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Muestra las etiquetas de los nodos">
                                    <input class="form-check-input" name="showLabels" type="checkbox" id="showLabels" formControlName="showLabels">
                                    <label class="form-check-label" for="showLabels">
                                        Mostrar etiquetas
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-2">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Usa el algoritmo Pathfinder para podar enlaces del grafo">
                                    <input class="form-check-input" name="pathfinder" type="checkbox" id="pathfinder" formControlName="pathfinder">
                                    <label class="form-check-label" for="pathfinder">
                                        Pathfinder
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-3">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Evita que aparezcan en el grafo los términos usados en el tema">
                                    <input class="form-check-input" name="equationAsStopWord" type="checkbox" id="equationAsStopWord" formControlName="equationAsStopWord">
                                    <label class="form-check-label" for="equationAsStopWord">
                                        Ecuación como Stopwords
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-2">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Usa solo documentos de 'Mi Carpeta'">
                                    <input class="form-check-input" name="useFolderIds" type="checkbox" id="useFolderIds" formControlName="useFolderIds">
                                    <label class="form-check-label" for="useFolderIds">
                                        Usar solo "Mi Carpeta"
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-row justify-content-end">
                            <div class="form-group col-12">
                                &nbsp;
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-outline-success" type="submit"
                                            [disabled]="mapForm.invalid || (loading$ | async) === true">Graficar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                 role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row mt-1" *ngIf="(map$|async).nodes.length>0">
        <div class="col-12">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" href="#" [ngClass]="showGraph === true?'active':''" (click)="$event.preventDefault();showGraphDiv();">Mapa</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" [ngClass]="showTable === true?'active':''" (click)="$event.preventDefault();showTableDiv();">Tabla de datos</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" [ngClass]="showCentrality === true?'active':''" (click)="$event.preventDefault();showCentralityDiv();">Centralidad</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mt-2" [ngClass]="(showTable==true && (map$|async).nodes.length > 0)?'d-block':'d-none'">
        <div class="col-12">
            <table class="table table-bordered table-sm table-striped shadow">
                <tr>
                    <td>Etiqueta</td>
                    <td>Etiqueta</td>
                    <td>Cantidad</td>
                </tr>
                <tr *ngFor="let item of (map$|async).links">
                    <td>{{item.source.name}}</td>
                    <td>{{item.target.name}}</td>
                    <td>{{item.value}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row mt-2" [ngClass]="(showCentrality==true && (map$|async).nodes.length>0)?'d-block':'d-none'">
        <div class="col-12">
            <table class="table table-bordered table-sm table-striped">
                <tr>
                    <td style="cursor: pointer" (click)="sortByNodeNameCentrality()">
                        Nodo
                        <i class="fa fa-arrow-down text-primary"></i>
                    </td>
                    <td style="cursor: pointer" (click)="sortByCentrality()"  placement="top" ngbTooltip="Razón de enlaces del nodo contra el total de enlaces del mapa">
                        Centralidad
                        <i class="fa fa-arrow-down text-primary"></i>
                    </td>
                </tr>
                <tr *ngFor="let item of (centrality$|async)">
                    <td>{{item.name}}</td>
                    <td>{{item.centrality}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row mt-2" [ngClass]="(showGraph==true && (map$|async).nodes.length>0)?'d-block':'d-none'">
        <div class="col-12">
            <div class="card shadow">
                <div class="card-body">
                    <div class="card-title">
                        <div class="row d-flex justify-content-between text-center">
                            <div class="col-12 col-md-3">
                                <strong placement="top" ngbTooltip="Menor/mayor cantidad de enlaces de nodos en el mapa">Relaciones (min/max): </strong>
                                <span id="legend1"></span>
                            </div>
                            <div class="col-12 col-md-3">
                                <strong placement="top" ngbTooltip="Valor del menor y mayor enlace">Ocurencias (min/max): </strong>
                                <span id="legend2"></span>
                            </div>
                            <div class="col-12 col-md-3">
                                <strong placement="top" ngbTooltip="Cantidad de elementos en el mapa">Nodos: </strong>
                                <span id="legend3"></span>
                            </div>
                            <div class="col-12 col-md-3">
                                <strong placement="top" ngbTooltip="Cantidad de enlaces en el mapa">Enlaces: </strong>
                                <span id="legend4"></span>
                            </div>
                        </div>
                    </div>
                    <svg class="disable-select" id="mapa"></svg>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(map$|async).nodes.length>0">
        <div class="col-12" *ngIf="(IPCDescription$|async).length > 0">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">
                        Leyenda:
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-ipc-legend [legend]="(IPCDescription$|async)"></app-ipc-legend>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="(CPCDescription$|async).length > 0">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">
                        Leyenda:
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-cpc-legend [legend]="(CPCDescription$|async)"></app-cpc-legend>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
