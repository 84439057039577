<div class="container-fluid">
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                 role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row small d-flex align-items-end mt-2">
        <div class="col-1 text-center px-0 d-none d-sm-inline-flex">&nbsp;</div>
        <div class="col text-center px-0" *ngIf="(metadata$|async).journals > 0">Revistas</div>
        <div class="col text-center px-0" *ngIf="(metadata$|async).articles > 0">Artículos</div>
        <div class="col text-center px-0" *ngIf="(metadata$|async).last_articles > 0">Últimos Artículos</div>
        <div class="col text-center px-0" *ngIf="(metadata$|async).patents > 0">Patentes</div>
        <div class="col text-center px-0" *ngIf="(metadata$|async).last_patents > 0">Últimas Patentes</div>
    </div>
    <div class="row d-flex">
        <div class="col-1 text-center d-none d-sm-inline-block mx-1 p-0">
            <img src="assets/img/{{(home$|async).vigilance.image}}" alt="" style="height: 55px">
        </div>
        <div class="col text-center text-white mx-1 py-2 px-0 rounded bg-primary" *ngIf="(metadata$|async).journals > 0">
            <h3>{{(metadata$|async).journals}}</h3>
        </div>
        <div class="col text-center text-white mx-1 py-2 px-0 rounded bg-success" *ngIf="(metadata$|async).articles > 0">
            <h3>{{(metadata$|async).articles}}</h3>
        </div>
        <div class="col text-center text-white mx-1 py-2 px-0 rounded bg-warning" *ngIf="(metadata$|async).last_articles > 0">
            <h3>{{(metadata$|async).last_articles}}</h3>
        </div>
        <div class="col text-center text-white mx-1 py-2 px-0 rounded bg-danger" *ngIf="(metadata$|async).patents > 0">
            <h3>{{(metadata$|async).patents}}</h3>
        </div>
        <div class="col text-center text-white mx-1 py-2 px-0 rounded bg-info" *ngIf="(metadata$|async).last_patents > 0">
            <h3>{{(metadata$|async).last_patents}}</h3>
        </div>
    </div>
    <div class="row mt-4 d-flex">
        <div class="col-1 text-center d-none d-sm-inline-block mx-1 p-0"><strong><i class="fas fa-download fa-2x" style="color: {{(core$ | async).menuColor}}"></i></strong></div>
        <div class="col text-center text-white mx-1 py-2 px-0 rounded bg-info" *ngFor="let u of (metadata$|async).article_last_updates">
            <h4>{{u.name}} - {{u.date}}</h4>
        </div>
        <div class="col text-center text-white mx-1 py-2 px-0 rounded bg-success" *ngFor="let u of (metadata$|async).patent_last_updates">
            <h4>{{u.name}} - {{u.date}}</h4>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link" routerLink="statistics" routerLinkActive="active">Estadisticas</a>
                </li>
                <li class="nav-item" *ngIf="(metadata$|async).last_articles > 0">
                    <a class="nav-link" routerLink="articles" routerLinkActive="active">Artículos</a>
                </li>
                <li class="nav-item" *ngIf="(metadata$|async).last_patents > 0">
                    <a class="nav-link" routerLink="patents" routerLinkActive="active">Patentes</a>
                </li>
                <li class="nav-item" *ngIf="(viglianceConfig$ | async).newsActive == true">
                    <a class="nav-link" routerLink="news" routerLinkActive="active">Noticias</a>
                </li>
                <li class="nav-item" *ngIf="(viglianceConfig$ | async).sicNewsLettersActive == true">
                    <a class="nav-link" routerLink="newsletters" routerLinkActive="active">Boletines</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
