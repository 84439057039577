import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {PatentStoreService} from '@app/meta-searcher/store/patent-store.service';
import {Patent} from '@app/shared/model/patent/patent';
import {EncodeUriService} from '@app/shared/encode-uri/encode-uri.service';
import {Title} from '@angular/platform-browser';
import {CoreStoreService} from '@app/core/store/core-store.service';
import {User} from '@app/shared/model/core/user';

@Component({
    selector: 'app-patent-view',
    templateUrl: './patent-view.component.html',
    styleUrls: ['./patent-view.component.scss']
})
export class PatentViewComponent implements OnInit {
    patent$: Observable<Patent>;
    loading$: Observable<boolean>;
    user$: Observable<User>;
    id = 0;

    constructor(private location: Location,
                private route: ActivatedRoute,
                private pss: PatentStoreService,
                private ts: Title,
                private css: CoreStoreService) {
        route.params.subscribe(params => {
            this.id = parseInt(params.id);
        });
    }

    ngOnInit(): void {
        this.patent$ = this.pss.observableValue$;
        this.loading$ = this.pss.loading$;
        this.user$= this.css.observableUser$;
        this.pss.getPatent(this.id);
    }

    goBack(): void {
        this.location.back();
    }

    encodeUri(text): string {
        return EncodeUriService.encodeUriComponent(text);
    }

    getCurrentUrl():string{
        return window.location.href;
    }

    getCurrentTitle(): string{
        return this.ts.getTitle();
    }
}
