<div class="container-fluid">
    <div class="row mt-2">
        <div class="col-12">
            <h4 class="text-center">Últimas Noticias</h4>
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(news$ | async).length > 0">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2" *ngFor="let n of (news$ | async)" [@simpleFadeAnimation]="'in'">
            <div class="card mt-1 shadow">
                <img *ngIf="n.content_type === 'image/jpeg'" style="width: 100%; height: auto" src="api/media/{{n.media}}" alt="{{n.media}}">
                <img *ngIf="n.content_type === 'image/gif'" style="width: 100%; height: auto" src="api/media/{{n.media}}" alt="{{n.media}}">
                <video controls width="100%" *ngIf="n.content_type === 'video/mp4'">
                    <source src="api/media/{{n.media}}" type="video/mp4">
                </video>
                <div class="row" *ngIf="n.content_type === 'application/pdf'">
                    <div class="col-12">
                        <a href="api/media/{{n.media}}">
                            <div class="media mx-4 mt-2">
                                <i class="fas fa-file-download fa-5x mr-3"></i>
                                <div class="media-body">
                                    <p class="mt-0">{{n.media}}</p>
                                    PDF
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="card-body">
                    <blockquote class="blockquote text-justify ">
                        <p style="font-size: 0.7em" class="text-justify" [innerHTML]="n.message | safe: 'html'"></p>
                        <footer class="text-right">{{n.channelname}}</footer>
                        <footer class="text-right small">{{n.date * 1000 | date : 'medium'}}</footer>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</div>
