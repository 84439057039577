import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Patent} from '@app/shared/model/patent/patent';
import {PatentsStoreService} from '@app/vigilance/store/patents-store.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ArticlesStoreService} from '@app/vigilance/store/articles-store.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';

@Component({
    selector: 'app-patents',
    templateUrl: './patents.component.html',
    styleUrls: ['./patents.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(600)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(600, style({opacity: 0})))
        ])
    ]
})
export class PatentsComponent implements OnInit {
    patents$: Observable<Patent[]>;
    loading$: Observable<any>;
    count$: Observable<number>;
    subscription: Subscription;

    constructor(private pss: PatentsStoreService,
                private ns: NotificatorService) {
    }

    ngOnInit(): void {
        this.patents$ = this.pss.observableValue$;
        this.loading$ = this.pss.loading$;
        this.count$ = this.pss.count$;

        this.subscription = this.ns.getNotification().subscribe(
            notification => {
                switch (notification.type) {
                    case 'login':
                    case 'logout':
                        this.pss.getLastPatents();
                        this.pss.getLastPatentsCount();
                        break;
                }
            }
        );
    }

}
