import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {WhoWeAre} from '@app/shared/model/who-we-are/who-we-are';
import {DataService} from '@app/shared/data/data.service';

@Injectable({
    providedIn: 'root'
})
export class WhoWeAreStoreService extends Store<WhoWeAre> {

    constructor(private ds: DataService) {
        super();
        const whoWeAre: WhoWeAre={
            content: [],
            tech:[],
            aknowledgements:[]
        }
        this.observableValue.next(whoWeAre);
        this.getWhoWeAreConfig();
    }

    getWhoWeAreConfig() {
        this.ds.observableClientConfig$.subscribe(
            res => this.observableValue.next(res.whoweare)
        );
    }
}
