import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class IsSecureGuardService implements CanActivate {

    constructor() {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (location.protocol !== 'https:') {
            location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
            return false;
        }
        return true;
    }
}
