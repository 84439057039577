import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {About} from '@app/shared/model/about/about';
import {AboutStoreService} from '@app/about/store/about-store.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(600)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(600, style({opacity: 0})))
        ])
    ]
})
export class AboutComponent implements OnInit {
    about$: Observable<About>;
    loading$: Observable<boolean>;

    constructor(private ass: AboutStoreService) {
    }

    ngOnInit(): void {
        this.about$ = this.ass.observableValue$;
        this.loading$ = this.ass.loading$;
    }

}
