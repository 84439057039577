import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Patent} from '@app/shared/model/patent/patent';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PatentsStoreService extends Store<Patent[]> {
    protected count: BehaviorSubject<number> = new BehaviorSubject(0);
    readonly count$ = this.count.asObservable();

    constructor(private ds: DataService,
                private ns: NotificatorService) {
        super();
        const patents: Patent[] = [];
        this.observableValue.next(patents);
        this.getLastPatents();
        this.getLastPatentsCount();
    }

    public getLastPatents() {
        this.loading.next(true);
        this.ds.getLastPatents().subscribe(
            res => {
                if (res.success == true) {
                    this.observableValue.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    public getLastPatentsCount() {
        this.ds.getLastPatentsCount().subscribe(
            res => {
                if (res.success == true) {
                    this.count.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }
}
