import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PersonProfile} from '@app/shared/model/profile/person-profile';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {ProfileStoreService} from '@app/meta-searcher/store/profile-store.service';

@Component({
    selector: 'app-tech-profile-view',
    templateUrl: './tech-profile-view.component.html',
    styleUrls: ['./tech-profile-view.component.scss']
})
export class TechProfileViewComponent implements OnInit {
    personProfile$: Observable<PersonProfile>;
    assigneeTitle = "Titulares";
    loading$: Observable<boolean>;

    constructor(private location: Location,
                private route: ActivatedRoute,
                private apss: ProfileStoreService) {
        route.params.subscribe(params => {
            if (params.type === 'inventor') {
                this.apss.getInventorProfile(parseInt(params.id));
                this.assigneeTitle = "Filiación";
            } else {
                this.apss.getAssigneeProfile(parseInt(params.id));
                this.assigneeTitle = "Socios Tecnológicos";
            }
        });
    }

    ngOnInit(): void {
        this.personProfile$ = this.apss.observableValue$;
        this.loading$ = this.apss.loading$;
    }

    goBack(): void {
        this.location.back();
    }
}
