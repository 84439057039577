import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {ArticleChunk} from '@app/shared/model/search/article-chunk';
import {CoreStoreService} from '@app/core/store/core-store.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {DataService} from '@app/shared/data/data.service';

@Injectable({
    providedIn: 'root'
})
export class ArticleFolderStoreService extends Store<ArticleChunk[]> {

    constructor(private css: CoreStoreService,
                private ns: NotificatorService,
                private ds: DataService) {
        super();
        this.observableValue.next([]);
        this.css.observableUser$.subscribe(
            res => {
                if (res.username !== '') {
                    this.init();
                } else {
                    this.observableValue.next([]);
                }
            }
        );
    }

    private init() {
        this.loading.next(true);
        this.ds.getArticles().subscribe(
            res => {
                if (res.success == true) {
                    this.observableValue.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    public getFolder(): ArticleChunk[] {
        if (this.css.haveSessionValue().username !== '') {
            return this.observableValue.getValue() || [];
        } else {
            return [];
        }
    }

    containsId(id: number): boolean {
        return this.getFolder().filter(function (article: ArticleChunk) {
            return article.id === id;
        }).length > 0;
    }

    addToFolder(article: ArticleChunk): boolean {
        if (this.css.haveSessionValue().username !== '') {
            if (!this.containsId(article.id)) {
                this.ds.addDocument(article.id, 'a').subscribe(
                    res => {
                        if (res.success == true) {
                            const folder = this.observableValue.getValue();
                            folder.push(article);
                            this.observableValue.next(folder);
                        } else {
                            this.ns.sendNotification({type: 'error', message: res.error});
                        }
                        this.loading.next(false);
                    },
                    error => {
                        this.ns.sendNotification({type: 'error', message: error.message});
                        this.loading.next(false);
                    }
                );
                return true;
            } else {
                return false;
            }
        } else {
            this.ns.sendNotification({message: 'Debe iniciar sesión para usar esta característica', type: 'warning'});
            return false;
        }
    }

    deleteFromFolder(article: ArticleChunk): boolean {
        if (this.css.haveSessionValue().username !== '') {
            if (this.containsId(article.id)) {
                this.ds.removeDocument(article.id, 'a').subscribe(
                    res => {
                        if (res.success == true) {
                            const aux: any = [];
                            Object.keys(this.getFolder()).forEach(function (element, index) {
                                aux.push(index);
                            });
                            const folder = this.observableValue.getValue();
                            for (const idx of aux) {
                                if (folder[idx].id === article.id) {
                                    this.ns.sendNotification({message: article, type: 'deleteArticle'});
                                    folder.splice(idx, 1);
                                    this.observableValue.next(folder);
                                    break;
                                }
                            }
                        } else {
                            this.ns.sendNotification({type: 'error', message: res.error});
                        }
                        this.loading.next(false);
                    },
                    error => {
                        this.ns.sendNotification({type: 'error', message: error.message});
                        this.loading.next(false);
                    }
                );
                return true;
            } else {
                return false;
            }
        } else {
            this.ns.sendNotification({message: 'Debe iniciar sesión para usar esta característica', type: 'warning'});
            return false;
        }
    }
}
