import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Footer} from '@app/shared/model/footer/footer';
import {DataService} from '@app/shared/data/data.service';

@Injectable({
    providedIn: 'root'
})
export class FooterStoreService extends Store<Footer> {

    constructor(private ds: DataService) {
        super();
        const footer: Footer = {
            prefooter: false,
            sponsor1: {url: "", title: "", lines: []},
            sponsor2: {url: "", title: "", lines: []},
            contacts: [],
            interestLinks: []

        }
        this.observableValue.next(footer);
        this.getFooterConfig();
    }

    getFooterConfig() {
        this.ds.observableClientConfig$.subscribe(
            res => this.observableValue.next(res.footer)
        );
    }
}
