<div style="min-height: 99vh;position: relative">
    <app-main-menu></app-main-menu>
    <div style="position: fixed; z-index: 2; top: 80px; left: 50%; transform: translate(-50%, 0);" class="card col-xl-3 col-lg-5 col-md-7 col-sm-9 col-11 shadow"  [ngClass]="(showConfirm==true)?'d-block':'d-none'">
        <div class="card-body disable-select">
            <div class="row" >
                <div class="col-12 d-flex">
                    <h5 class="d-inline-block mr-auto">Nueva Versión Disponible. ¿Desea instalarla?</h5>
                    <span style="cursor:pointer" (click)="showConfirm=false;"><i class="fas fa-window-close fa-2x"></i></span>
                </div>
            </div>
            <div class="form-row mt-1">
                <div class="col-6 text-center">
                    <button class="btn btn-primary" (click)="install()">Instalar</button>
                </div>
                <div class="col-6 text-center">
                    <button class="btn btn-secondary" (click)="showConfirm=false;">Cancelar</button>
                </div>
            </div>
        </div>
    </div>

    <div style="padding-bottom: 138px;">
        <router-outlet (activate)="onActivate($event)" ></router-outlet>
    </div>
    <app-footer style="position: absolute;bottom: 0;height: 138px;width: 100%"></app-footer>
</div>
