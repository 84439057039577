import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Patent} from '@app/shared/model/patent/patent';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';

@Injectable({
    providedIn: 'root'
})
export class PatentStoreService extends Store<Patent> {

    constructor(private ds: DataService,
                private ns: NotificatorService) {
        super();
        this.init();
    }

    private init() {
        const patent: Patent = {
            IPCs: [],
            Assignees: [],
            Inventors: [
                {
                    id: 0,
                    IN: '',
                    ICN:''
                }
            ],
            ABST: '',
            offices: [],
            ACC: '',
            ACLM: '',
            APD: '',
            APN: '',
            CC: '',
            CPCs: [],
            id: 0,
            ISD: '',
            KD: '',
            PN: '',
            TTL: ''
        };
        this.observableValue.next(patent);
    }

    getPatent(id: number) {
        if (id !== this.observableValue.getValue().id) {
            this.init();
            this.loading.next(true);
            this.ds.getPatent(id).subscribe(
                res => {
                    if (res.success == true) {
                        res.data.ACLM = res.data.ACLM.split(/^\d{1,2}\./gm).slice(1, res.data.ACLM.length);
                        this.observableValue.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'error', message: res.error});
                    }
                    this.loading.next(false);
                },
                error => {
                    this.ns.sendNotification({type: 'error', message: error.message});
                    this.loading.next(false);
                }
            );
        }
    }
}
