import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Map} from '@app/shared/model/metrics/map';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {BehaviorSubject} from 'rxjs';
import {IPCDescription} from '@app/shared/model/metrics/ipc-description';
import {CPCDescription} from '@app/shared/model/metrics/cpc-description';
import {MapForm} from '@app/shared/model/metrics/map-form';
import {MapBuilderService} from '@app/shared/map-builder/map-builder.service';
import * as _ from 'lodash';
import {CentralityNode} from '@app/shared/model/metrics/centrality-node';
import {ArticleFolderStoreService} from '@app/shared/folder/article-folder-store.service';
import {PatentFolderStoreService} from '@app/shared/folder/patent-folder-store.service';

@Injectable({
    providedIn: 'root'
})
export class MapStoreService extends Store<Map> {
    private observableIPCDescription: BehaviorSubject<IPCDescription[]> = new BehaviorSubject<IPCDescription[]>([]);
    readonly observableIPCDescription$ = this.observableIPCDescription.asObservable();

    private observableCPCDescription: BehaviorSubject<CPCDescription[]> = new BehaviorSubject<CPCDescription[]>([]);
    readonly observableCPCDescription$ = this.observableCPCDescription.asObservable();

    private observableSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    readonly oobservableSubject$ = this.observableSubject.asObservable();

    private observableForm: BehaviorSubject<MapForm> = new BehaviorSubject<MapForm>({
        indcode: '',
        yearMin: 2015,
        yearMax: parseInt(new Date().toISOString().split('-')[0], 10),
        filter: '',
        top: '',
        isLast: false,
        gravity: 0.9,
        charge: 800,
        limitNodes: 30,
        pathfinder: false,
        fontSize: 16,
        showLabels: true,
        equationAsStopWord: false,
        useFolderIds: false,
        folderIds: [],
        subject: 'Todos'
    });
    readonly observableForm$ = this.observableForm.asObservable();

    private observableCentrality: BehaviorSubject<CentralityNode[]> = new BehaviorSubject<CentralityNode[]>([]);
    readonly centrality$ = this.observableCentrality.asObservable();

    constructor(private ds: DataService,
                private ns: NotificatorService,
                private afss: ArticleFolderStoreService,
                private pfss: PatentFolderStoreService) {
        super();
        this.observableValue.next({nodes: [], links: []});
        this.getSubjects();
    }

    getSubjects(): void {
        this.loading.next(true);
        this.ds.getSubjects().subscribe(
            res => {
                if (res.success === true) {
                    this.observableForm.next({
                        indcode: '',
                        yearMin: 2015,
                        yearMax: parseInt(new Date().toISOString().split('-')[0], 10),
                        filter: '',
                        top: '',
                        isLast: false,
                        gravity: 0.9,
                        charge: 800,
                        limitNodes: 30,
                        pathfinder: false,
                        fontSize: 16,
                        showLabels: true,
                        equationAsStopWord: false,
                        useFolderIds: false,
                        folderIds: [],
                        subject: res.data[0]
                    });
                    this.observableSubject.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    getMapIndicator(body): void {
        this.loading.next(true);
        this.observableForm.next({
            indcode: body.indcode,
            yearMin: body.yearMin,
            yearMax: body.yearMax,
            filter: body.filter,
            top: body.top,
            isLast: body.isLast,
            gravity: body.gravity,
            charge: body.charge,
            limitNodes: body.limitNodes,
            pathfinder: body.pathfinder,
            fontSize: body.fontSize,
            showLabels: body.showLabels,
            equationAsStopWord: body.equationAsStopWord,
            useFolderIds: body.useFolderIds,
            folderIds: body.folderIds,
            subject: body.subject
        });
        body.folderIds = [];
        if (body.useFolderIds == true) {
            if (body.indcode.includes('ART')) {
                this.afss.getFolder().map(a => {
                    body.folderIds.push(a.id);
                });
            } else {
                this.pfss.getFolder().map(p => {
                    body.folderIds.push(p.id);
                });
            }
        }
        this.ds.getMapIndicator(body).subscribe(
            res => {
                if (res.success == true) {
                    let symbols = []; // classifications symbols
                    res.data.nodes = res.data.nodes.map(n => {
                        // if name is not a classification symbol then Capitalize words else push to symbols
                        if (!(n.group == '00FF80' || n.group == '9876AA')) {
                            n.name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                        } else {
                            symbols.push(n.name);
                        }
                        return n;
                    });
                    if (res.data.nodes.length == 0) {
                        this.ns.sendNotification({type: 'warning', message: 'No hubo resultados'});
                    }
                    res.data.links = _.orderBy(res.data.links, 'value', 'desc');
                    // centrality measure
                    let centralityDegree: CentralityNode[] = [];
                    _.forEach(res.data.nodes, n => {
                        let c = 0;
                        _.forEach(res.data.links, l => {
                            if (l.source === n.id || l.target === n.id) {
                                c++;
                            }
                        });
                        centralityDegree.push({name: n.name, centrality: c / (res.data.nodes.length - 1)});
                    });
                    centralityDegree = _.orderBy(centralityDegree, 'centrality', 'desc');
                    this.observableCentrality.next(centralityDegree);
                    this.observableValue.next(res.data);
                    MapBuilderService.loadMap(res.data, this.observableForm.getValue().gravity,
                        this.observableForm.getValue().charge, this.observableForm.getValue().fontSize, this.observableForm.getValue().showLabels);
                    this.updateClasificationLegend(symbols);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    loadMap(): void {
        MapBuilderService.loadMap(this.observableValue.getValue(), this.observableForm.getValue().gravity,
            this.observableForm.getValue().charge, this.observableForm.getValue().fontSize, this.observableForm.getValue().showLabels);
    }

    private updateClasificationLegend(symbols: string[]): void {
        if (this.observableForm.getValue().indcode.includes('IPC') && symbols.length > 0) {
            this.ds.getIPCDescription(symbols).subscribe(
                res => {
                    if (res.success === true) {
                        this.observableIPCDescription.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'warning', message: res.error});
                    }
                },
                error => this.ns.sendNotification({type: 'error', message: error.message})
            );
        }

        if (this.observableForm.getValue().indcode.includes('CPC') && symbols.length > 0) {
            this.ds.getCPCDescription(symbols).subscribe(
                res => {
                    if (res.success === true) {
                        this.observableCPCDescription.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'warning', message: res.error});
                    }
                },
                error => this.ns.sendNotification({type: 'error', message: error.message})
            );
        }
    }

    sortByCentrality(){
        let centralityDegree = this.observableCentrality.getValue();
        centralityDegree = _.orderBy(centralityDegree, 'centrality', 'desc');
        this.observableCentrality.next(centralityDegree);
    }

    sortByNodeNameCentrality(){
        let centralityDegree = this.observableCentrality.getValue();
        centralityDegree = _.orderBy(centralityDegree, 'name', 'asc');
        this.observableCentrality.next(centralityDegree);
    }
}
