<footer class="mt-2" style="background-color: #f1f1f1;">
    <div class="container" *ngIf="(footer$|async)?.prefooter">
        <div class="row">
            <div class="col-12 col-md-4 text-center mt-2"><img style="max-height: 40px" class="img-fluid" src="assets/img/sponsor3.png" alt=""></div>
            <div class="col-12 col-md-4 text-center mt-2"><img style="max-height: 40px" class="img-fluid" src="assets/img/sponsor4.png" alt=""></div>
            <div class="col-12 col-md-4 text-center mt-2"><img style="max-height: 40px" class="img-fluid" src="assets/img/sponsor5.png" alt=""></div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row align-items-center justify-content-around">
            <div class="col-12 col-md-6 col-lg-3">
                <div class="row mb-3">
                    <div class="col-12"><a href="#" [routerLink]="'/whoweare'">¿Quiénes Somos?</a></div>
                </div>
                <div class="row">
                    <div class="col-12">Hecho por:</div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="media-logo">
                            <img  class="" src="assets/img/sponsor1.png" style="height: 36px">
                            <span class="desc">
                                <em>{{(footer$|async).sponsor1.title}}</em>
                                <small *ngFor="let l of (footer$|async).sponsor1.lines">{{l}}<br></small>
                                <a href="{{(footer$|async).sponsor1.url}}">{{(footer$|async).sponsor1.url}}</a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
                <div class="row">
                    <div class="col-12">Contactos:</div>
                </div>
                <div class="row">
                    <div class="col-12" *ngFor="let c of (footer$ | async).contacts">
                        {{c.name}}<br>
                        <a href="mailto:{{c.email}}">{{c.email}}</a>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
                <div class="row">
                    <div class="col-12">Enlaces de interés:</div>
                </div>
                <div class="row">
                    <div class="col-12" *ngFor="let il of (footer$|async).interestLinks"><a rel="noreferrer" target="_blank" href="{{il.url}}">{{il.name}}</a></div>
                </div>
            </div>

            <div class="col-12 col-md-6 col-lg-3">
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="media-logo">
                            <img  class="" src="assets/img/sponsor2.png" style="height: 35px">
                            <span class="desc">
                                <em>{{(footer$|async).sponsor2.title}}</em>
                                <small *ngFor="let l of (footer$|async).sponsor2.lines">{{l}}<br></small>
                                <a href="{{(footer$|async).sponsor2.url}}">{{(footer$|async).sponsor2.url}}</a>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row  text-center">
                    <div class="col-12">Licencia Creative Commons 4.0</div>
                    <div class="col-12 text-center"><img src="assets/img/license.png" alt=""></div>
                    <div class="col-12 text-center">CC BY-NC-SA</div>
                    <div class="col-12">Hecho con Software Libre</div>
                </div>
            </div>
        </div>
    </div>
</footer>
