import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {VigilanceStoreService} from '@app/vigilance/store/vigilance-store.service';
import {Metadata} from '@app/shared/model/vigilance/metadata';
import {HomeStoreService} from '@app/home/store/home-store.service';
import {Home} from '@app/shared/model/home/home';
import {VigilanceConfig} from '@app/shared/model/vigilance/vigilance-config';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {CoreStoreService} from '@app/core/store/core-store.service';
import {Core} from '@app/shared/model/core/core';

@Component({
    selector: 'app-vigilance',
    templateUrl: './vigilance.component.html',
    styleUrls: ['./vigilance.component.scss']
})
export class VigilanceComponent implements OnInit {
    loading$: Observable<boolean>;
    metadata$: Observable<Metadata>;
    home$:Observable<Home>;
    core$:Observable<Core>;
    viglianceConfig$: Observable<VigilanceConfig>;
    subscription: Subscription;

    constructor(private vss: VigilanceStoreService,
                private hss: HomeStoreService,
                private ns: NotificatorService,
                private css: CoreStoreService) {
    }

    ngOnInit(): void {
        this.metadata$ = this.vss.observableValue$;
        this.loading$ = this.vss.loading$;
        this.home$ = this.hss.observableValue$;
        this.core$ = this.css.observableValue$;
        this.viglianceConfig$ = this.vss.observableVigilanceConfig$;

        this.subscription = this.ns.getNotification().subscribe(
            notification => {
                switch (notification.type) {
                    case 'login':
                    case 'logout':
                        this.vss.getMetadata();
                        break;
                }
            }
        );
    }

}
