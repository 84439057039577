import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Home} from '@app/shared/model/home/home';
import {DataService} from '@app/shared/data/data.service';

@Injectable({
    providedIn: 'root'
})
export class HomeStoreService extends Store<Home> {

    constructor(private ds: DataService) {
        super();
        const home: Home = {
            extTool: {
                name: '',
                image: '',
                description: '',
                url: '',
                activated: false
            },
            toolsNameColor: '#000000',
            metasearcher: {
                name: '',
                image: '',
                description: ''
            },
            metrics: {
                name: '',
                image: '',
                description: ''
            },
            vigilance: {
                name: '',
                image: '',
                description: ''
            }
        };
        this.observableValue.next(home);
        this.getHomeConfig();
    }

    getHomeConfig() {
        this.ds.observableClientConfig$.subscribe(
            res => this.observableValue.next(res.home)
        );
    }
}
