import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {NotFoundComponent} from '@app/core/not-found/not-found.component';
import {IsSecureGuardService} from '@app/core/is-secure-guard/is-secure-guard.service';
import {HomeComponent} from '@app/home/home/home.component';
import {AboutComponent} from '@app/about/about/about.component';
import {WhoWeAreComponent} from '@app/who-we-are/who-we-are/who-we-are.component';
import {MetaSearcherComponent} from '@app/meta-searcher/meta-searcher/meta-searcher.component';
import {ArticleViewComponent} from '@app/meta-searcher/article-view/article-view.component';
import {PatentViewComponent} from '@app/meta-searcher/patent-view/patent-view.component';
import {TechProfileViewComponent} from '@app/meta-searcher/tech-profile-view/tech-profile-view.component';
import {SciProfileViewComponent} from '@app/meta-searcher/sci-profile-view/sci-profile-view.component';
import {MetricsComponent} from '@app/metrics/metrics/metrics.component';
import {FrequencyComponent} from '@app/metrics/frequency/frequency.component';
import {MapComponent} from '@app/metrics/map/map.component';
import {VigilanceComponent} from '@app/vigilance/vigilance/vigilance.component';
import {NewsComponent} from '@app/vigilance/news/news.component';
import {NewsLettersComponent} from '@app/vigilance/news-letters/news-letters.component';
import {ArticlesComponent} from '@app/vigilance/articles/articles.component';
import {PatentsComponent} from '@app/vigilance/patents/patents.component';
import {StatisticsComponent} from '@app/vigilance/statistics/statistics.component';
import {HelpComponent} from '@app/help/help/help.component';
import {FolderComponent} from '@app/folder/folder/folder.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'about',
        component: AboutComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'whoweare',
        component: WhoWeAreComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'metasearcher',
        component: MetaSearcherComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'metasearcher/article/:id',
        component: ArticleViewComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'metasearcher/patent/:id',
        component: PatentViewComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'metasearcher/techprofile/:type/:id',
        component: TechProfileViewComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'metasearcher/sciprofile/:id',
        component: SciProfileViewComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'metrics',
        component: MetricsComponent,
        children: [
            {
                path: '',
                redirectTo: 'frequency',
                pathMatch: 'full'
            },
            {
                path: 'frequency',
                component: FrequencyComponent
            },
            {
                path: 'map',
                component: MapComponent
            }
        ],
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'vigilance',
        component: VigilanceComponent,
        children: [
            {
                path: '',
                redirectTo: 'statistics',
                pathMatch: 'full'
            },
            {
                path: 'news',
                component: NewsComponent
            },
            {
                path: 'newsletters',
                component: NewsLettersComponent
            },
            {
                path: 'articles',
                component: ArticlesComponent
            },
            {
                path: 'patents',
                component: PatentsComponent
            },
            {
                path: 'statistics',
                component: StatisticsComponent
            }
        ],
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'help',
        component: HelpComponent,
        canActivate: [IsSecureGuardService]
    },
    {
        path: 'folder',
        component: FolderComponent,
        canActivate: [IsSecureGuardService]
    },
    {path: 'notfound', component: NotFoundComponent},
    {path: '**', redirectTo: '/notfound'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class CoreRoutingModule {
}
