import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MapStoreService} from '@app/metrics/store/map-store.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {IPCDescription} from '@app/shared/model/metrics/ipc-description';
import {CPCDescription} from '@app/shared/model/metrics/cpc-description';
import {Map} from '@app/shared/model/metrics/map';
import {Metadata} from '@app/shared/model/vigilance/metadata';
import {VigilanceStoreService} from '@app/vigilance/store/vigilance-store.service';
import {CentralityNode} from '@app/shared/model/metrics/centrality-node';
import {User} from '@app/shared/model/core/user';
import {CoreStoreService} from '@app/core/store/core-store.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
    IPCDescription$: Observable<IPCDescription[]>;
    CPCDescription$: Observable<CPCDescription[]>;
    map$: Observable<Map>;
    centrality$: Observable<CentralityNode[]>;
    showGraph = true;
    showTable = false;
    showCentrality = false;
    mapForm: FormGroup;
    loading$: Observable<boolean>;
    metadata$: Observable<Metadata>;
    loadingMetadata$: Observable<boolean>;
    user$: Observable<User>;
    subjects$: Observable<string[]>;
    mockObservablemetadata: BehaviorSubject<Metadata> = new BehaviorSubject<Metadata>({
        patents: 0,
        articles: 0,
        journals: 0,
        last_articles: 0,
        last_patents: 0,
        article_last_updates: [],
        patent_last_updates: []
    });
    currentYear = parseInt(new Date().toISOString().split('-')[0], 10);
    
    constructor(private mss: MapStoreService,
                private fb: FormBuilder,
                private vss: VigilanceStoreService,
                private css: CoreStoreService) {
    }

    ngOnInit(): void {
        let c = 0;// flag, to know if is the first view of map
        this.map$ = this.mss.observableValue$;
        this.centrality$ = this.mss.centrality$;
        this.loading$ = this.mss.loading$;
        this.IPCDescription$ = this.mss.observableIPCDescription$;
        this.CPCDescription$ = this.mss.observableCPCDescription$;
        this.metadata$ = this.mockObservablemetadata;
        this.user$ = this.css.observableUser$;
        this.mss.observableForm$.subscribe(
            res => {
                this.mapForm = this.fb.group({
                    indcode: [res.indcode, [Validators.required]],
                    yearMin: [res.yearMin],
                    yearMax: [res.yearMax],
                    filter: [res.filter],
                    top: [res.top],
                    isLast: [res.isLast],
                    gravity: [res.gravity],
                    charge: [res.charge],
                    limitNodes: [res.limitNodes],
                    pathfinder: [res.pathfinder],
                    fontSize: [res.fontSize],
                    showLabels: [res.showLabels],
                    equationAsStopWord: [res.equationAsStopWord],
                    useFolderIds: [res.useFolderIds],
                    subject: [res.subject]
                });
                // reload map if user return from another view
                if (res.indcode !== '' && c == 0) {
                    c++;
                    this.mss.loadMap();
                }
            }
        );
        this.subjects$ = this.mss.oobservableSubject$;
    }

    onChangeisLast() {
        if (this.mapForm.value.isLast === false) {
            this.metadata$ = this.vss.observableValue$;
            this.loadingMetadata$ = this.vss.loading$;
        }
    }

    onSubmit(): void {
        if ((this.mapForm.value.indcode == 'ART_CWABST' || this.mapForm.value.indcode == 'ART_CWTTLABST') && this.mapForm.value.filter == '') {
            if (!confirm('Por favor, considere filtrar el indicador. Usted a seleccionado un indicador computacionalmente costoso.' +
                'Pulse OK para detener y filtrar o Cancelar para continuar a pesar de la advertencia')) {
                this.mss.getMapIndicator(this.mapForm.value);
            }
        } else {
            this.mss.getMapIndicator(this.mapForm.value);
        }
    }

    showGraphDiv(): void {
        this.showGraph = true;
        this.showTable = false;
        this.showCentrality = false;
    }

    showTableDiv(): void {
        this.showGraph = false;
        this.showTable = true;
        this.showCentrality = false;
    }

    showCentralityDiv(): void {
        this.showGraph = false;
        this.showTable = false;
        this.showCentrality = true;
    }

    sortByCentrality(){
        this.mss.sortByCentrality();
    }

    sortByNodeNameCentrality(){
        this.mss.sortByNodeNameCentrality();
    }
}
