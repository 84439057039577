import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Home} from '@app/shared/model/home/home';
import {HomeStoreService} from '@app/home/store/home-store.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(600)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(600, style({opacity: 0})))
        ])
    ]
})
export class HomeComponent implements OnInit {
    home$: Observable<Home>;
    loading$: Observable<any>;
    extToolUrl = '';

    constructor(private hss: HomeStoreService) {
    }

    ngOnInit(): void {
        this.home$ = this.hss.observableValue$;
        this.loading$ = this.hss.loading$;
        this.hss.observableValue$.subscribe(
            res => {
                this.extToolUrl = res.extTool.url;
            }
        );
    }

    goExtTool() {
        window.location.href = this.extToolUrl;
    }
}
