<div class="container-fluid">
    <div class="row mt-2">
        <div class="col-12">
            <h4 class="text-center">Últimos Artículos <small *ngIf="(count$|async) != 0">({{(count$|async)}})</small></h4>
            <h6 class="text-center small">*Artículos que entraron al observatorio en la última actualización</h6>
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(articles$ | async).length > 0">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2" *ngFor="let a of (articles$ | async)" [@simpleFadeAnimation]="'in'">
            <div class="card mt-1 shadow" routerLink="//metasearcher/article/{{a.id}}" style="cursor: pointer">
                <div class="card-header">
                    <h6 class="card-title my-0 text-center"><strong>{{a.title}}</strong></h6>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12" *ngIf="a.sources.length > 0">
                            <strong>Fuente: </strong>
                            <span *ngFor="let s of a.sources; index as i"><span *ngIf="i>0"> ,</span>{{s}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <strong>Autor: </strong>{{a.authors[0].name}}
                            <span *ngIf="a.authors.length > 1">et, al.</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="a.authors[0].orcid != null && a.authors[0].orcid.length > 0">
                        <div class="col-12">
                            <strong>orcid: </strong><a
                            href="https://orcid.org/{{a.authors[0].orcid}}">{{a.authors[0].orcid}}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <span *ngIf="a.year != null"><strong>Año: </strong>{{a.year}}</span>
                            <span *ngIf="a.month != null && a.month > 0"><strong>, Mes: </strong>{{a.month}}</span>
                            <span *ngIf="a.journal.title != null"><strong><br> Revista: </strong>{{a.journal.title}}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="a.abstract != ''">
                        <div class="col-12 text-center"><strong>Resumen</strong></div>
                        <div class="col-12 small text-justify">{{a.abstract.slice(0,200)}}...</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
