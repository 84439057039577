<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h4>Sintaxis del lenguaje de consulta</h4>
        </div>
        <div class="col-12">
            *Usado para recuperar documentos en <span style="color: {{(home$|async).toolsNameColor}}">{{(home$|async).metasearcher.name}}</span>
            y para filtrar los indicadores en <span style="color: {{(home$|async).toolsNameColor}}">{{(home$|async).metrics.name}}</span>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <strong>Operadores Booleanos</strong>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <strong>OR</strong><br>
            <em>usar como:</em><br>
            OR, |
        </div>
        <div class="col-3">Aprendizaje OR Lenguaje <br> Neurociencias <strong>OR</strong> Neurodesarrollo <br> Learning <strong>OR</strong> Dyscalculia</div>
        <div class="col-5">Localiza documentos que incluyan al menos uno de los términos empleados</div>
        <div class="col-2"><img class="img-fluid" src="assets/img/or.png" alt="or"></div>
    </div>
    <div class="row">
        <div class="col-2">
            <strong>NOT</strong><br>
            <em>usar como:</em><br>
            -, !
        </div>
        <div class="col-3">Aprendizaje <strong>–</strong>Lenguaje <br>Aprendizaje <strong>!</strong>Lenguaje <br>Dyscalculia <strong>–</strong>Language <br>Language <strong>!</strong>Dyslexia</div>
        <div class="col-5">Recupera documentos que contengan el primer término, pero no el segundo</div>
        <div class="col-2"><img class="img-fluid" src="assets/img/not.png" alt="not"></div>
    </div>
    <div class="row">
        <div class="col-2">
            <strong>AND</strong><br>
            <em>usar como:</em><br>
            AND, [espacio]
        </div>
        <div class="col-3">Este operador está implícito, o sea, <em>Neurociencias Cognitivas</em> realmente significa <em>Neurociencias <strong>AND</strong> Cognitivas</em></div>
        <div class="col-5">Localiza documentos que incluyan todos los términos de la ecuación</div>
        <div class="col-2"><img class="img-fluid" src="assets/img/and.png" alt="and"></div>
    </div>
    <div class="row">
        <div class="col-2">
            <strong>( )</strong>
        </div>
        <div class="col-3">(Neurociencias | Neurodesarrollo) (Lenguaje -Aprendizaje)</div>
        <div class="col-5">En este ejemplo se localizan documentos que incluyan los vocablos Neurociencias o Neurodesarrollo acompañados del término lenguaje, pero no puede aparecer el término aprendizaje</div>
        <div class="col-2"></div>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            <strong>Sintaxis de query extendida</strong>
        </div>
        <div class="col-12">
            Los siguientes operadores y modificadores pueden ser usados: <br>
            - operador MAYBE <br>
            hola MAYBE mundo <br>
            - operador búsqueda por campo (ver más abajo campos disponibles): <br>
            @TTL hola @ACLM mundo <br>
            -Modificador límite de posición de campo: <br>
            @TTL[50] hola <br>
            -operador de búsqueda en múltiples campos: <br>
            @(TTL,ACLM) hola mundo <br>
            -operador de búsqueda de frase: <br>
            "hola mundo" <br>
            -operador de proximidad: <br>
            "hola mundo"~10 <br>
            -operador de orden estricto(también conocido como "before"): <br>
            aaa << bbb << ccc <br>
            -modificador de forma exacta: <br>
            carrera =gato y =perro <br>
            -operador de proximidad generalizado ("near"): <br>
            hola near/3 mundo near/4 "mi prueba" <br>
            -operador de párrafo (PARAGRAPH): <BR>
            "bill gates" PARAGRAPH "Steve Jobs" <br>
        </div>
        <div class="col-12 text-justify">
            <b> Ranking en el buscador de datos bibliográficos</b><br>
            Los documentos recuperados se organizarán siguiendo una combinación de "Longest Common Subsequence (LCS)" y
            "Binary Independent Retrieval (BM25)". Son funciones de ranking utilizadas en <em>Recuperación de información</em> para la
            asignación de relevancia a los documentos en un buscador, dicho de otra forma, son funciones que nos
            permiten ordenar por relevancia los documentos que contienen las palabras que el usuario ha introducido en
            la caja de búsqueda.
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 col-lg-6">
            <b>Lista de campos disponibles: Artículos</b><br>
            <table class="table table-bordered table-sm table-striped">
                <thead>
                <tr>
                    <th>Campo</th>
                    <th>Descripción</th>
                    <th>Fuentes</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style="border-left: 3px solid rgb(68,114,196)">AN</td>
                    <td>Nombre del autor</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(68,114,196)">AAN</td>
                    <td>Filiación del autor</td>
                    <td>DOAJ, PUBMED</td>
                </tr>

                <tr>
                    <td style="border-left: 3px solid rgb(255,192,0)">YEAR</td>
                    <td>Año</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(255,192,0)">MTH</td>
                    <td>Mes</td>
                    <td>DOAJ, PUBMED</td>
                </tr>

                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">TTL</td>
                    <td>Título</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">ABST</td>
                    <td>Resumen</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">KWRD</td>
                    <td>Palabras Clave</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">SUBJ</td>
                    <td><a href="https://www.loc.gov/aba/publications/FreeLCC/freelcc.html">Materia</a>. Tópicos de la Clasificación de la Bibloteca del Congreso.(Library of Congress Classification (LCC))</td>
                    <td>DOAJ</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">MESH</td>
                    <td><a href="https://www.ncbi.nlm.nih.gov/mesh/">MeSH</a>. Medical Subject Headings es un vocabulario terminológico controlado para publicaciones de artículos y libros de ciencia</td>
                    <td>PUBMED</td>
                </tr>

                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">NO</td>
                    <td>Número</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">VOL</td>
                    <td>Volumen</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">EISSN</td>
                    <td>ISSN Electrónico</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">PISSN</td>
                    <td>ISSN Impreso</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">JCN</td>
                    <td>Código de país de la revista</td>
                    <td>DOAJ</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">JPN</td>
                    <td>Nombre de editor de la revista</td>
                    <td>DOAJ</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">JLNG</td>
                    <td>Idioma de la Revista</td>
                    <td>DOAJ, PUBMED</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">SRC</td>
                    <td>Fuente. Base de datos científica de donde provienen los artículos</td>
                    <td>--</td>
                </tr>
                <tr>
                    <td colspan="3"><h5>Leyenda</h5></td>
                </tr>
                <tr>
                    <td colspan="3" style="border-left: 3px solid rgb(68,114,196)">Datos de investigadores y profesionales</td>
                </tr>
                <tr>
                    <td colspan="3" style="border-left: 3px solid rgb(255,192,0)">Datos temporalidad</td>
                </tr>
                <tr>
                    <td colspan="3" style="border-left: 3px solid rgb(112,173,71)">Datos sobre contenido del documento</td>
                </tr>
                <tr>
                    <td colspan="3" style="border-left: 3px solid rgb(237,125,49)">Datos de la Fuente bibliográfica</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 col-lg-6">
            <b>Lista de campos disponibles: Patentes</b><br>
            <table class="table table-bordered table-sm table-striped">
                <thead>
                <tr>
                    <th>Campo</th>
                    <th>Descripción</th>
                    <th>Fuentes</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">CC</td>
                    <td>Código del país de la patente</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">PN</td>
                    <td>Número</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">KD</td>
                    <td><a href="https://www.wipo.int/patentscope/en/data/kind_codes.html">Kind Code</a>. Tipo de patente</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">ISD</td>
                    <td>Fecha de publicación</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">TTL</td>
                    <td>Título</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">APN</td>
                    <td>Número de solicitud</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">APD</td>
                    <td>Fecha de solicitud</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">ACC</td>
                    <td>País de solicitud</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">ACLM</td>
                    <td>Reivindicaciones</td>
                    <td>USPTO Grants</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">ABST</td>
                    <td>Resumen</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(112,173,71)">SRC</td>
                    <td>Fuente. Base de datos de donde proviene la patente</td>
                    <td>--</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">CPC</td>
                    <td><a href="https://www.cooperativepatentclassification.org/index">Clasificación Cooperativa de Patentes</a></td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(237,125,49)">ICL</td>
                    <td><a href="https://www.wipo.int/classifications/ipc/en/">Clasificación Internacional de Patentes</a></td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(91,155,213)">AN</td>
                    <td>Nombre titular/solicitante</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(91,155,213)">ACN</td>
                    <td>Código de país del titular/solicitante</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(91,155,213)">IN</td>
                    <td>Nombre inventor</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td style="border-left: 3px solid rgb(91,155,213)">ICN</td>
                    <td>Código de país del inventor</td>
                    <td>USPTO Grants, USPTO Applications</td>
                </tr>
                <tr>
                    <td colspan="3"><h5>Leyenda</h5></td>
                </tr>
                <tr>
                    <td colspan="3" style="border-left: 3px solid rgb(112,173,71)">Datos patentes</td>
                </tr>
                <tr>
                    <td colspan="3" style="border-left: 3px solid rgb(237,125,49)">Datos de clasificación de patentes</td>
                </tr>
                <tr>
                    <td colspan="3" style="border-left: 3px solid rgb(91,155,213)">Datos titulares e inventores</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 text-center">
            <h4><span style="color: {{(home$|async).toolsNameColor}}">{{(home$|async).metrics.name}}:</span> análisis de frecuencia</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <h5>¿Qué deseas hacer?</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(91,155,213)">Análisis por año</div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(237,125,49)">Análisis de investigadores y profesionales</div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(112,173,71)">Análisis de países ó instituciones</div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(112,48,160)">Análisis de contenido y clasificaciones</div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 text-center ">
                    <strong>Indicadores de Artículos</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-sm table-striped">
                        <tbody>
                        <tr style="border-left: 3px solid rgb(91,155,213)"><td>Producción por años</td></tr>
                        <tr style="border-left: 3px solid rgb(237,125,49)"><td>Producción por autores</td></tr>
                        <tr style="border-left: 3px solid rgb(237,125,49)"><td>Cantidad de colegas por autores</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>Producción por filiaciones</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>Producción por revistas</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Producción por materias</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Producción por palabras clave</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Producción por MeSH</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 text-center">
                    <strong>Indicadores de Patentes</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-sm table-striped">
                        <tbody>
                        <tr style="border-left: 3px solid rgb(91,155,213)"><td>Producción por años</td></tr>
                        <tr style="border-left: 3px solid rgb(237,125,49)"><td>Producción por inventores</td></tr>
                        <tr style="border-left: 3px solid rgb(237,125,49)"><td>Producción por titular</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>Producción por país del titular</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>Producción por país del inventor</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Producción por CIP [subclase]</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Producción por CCP [subclase]</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 text-center">
            <h4><span style="color: {{(home$|async).toolsNameColor}}">{{(home$|async).metrics.name}}:</span> análisis relacionales</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center">
            <h5>¿Qué deseas hacer?</h5>
        </div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(91,155,213)">Análisis de investigadores y profesionales</div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(237,125,49)">Análisis de instituciones</div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(112,48,160)">Análisis de profesionales e instituciones y países</div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(112,173,71)">Análisis de contenido</div>
    </div>
    <div class="row">
        <div class="col-12 ml-3" style="border-left: 3px solid rgb(255,192,0)">Análisis de contenido y profesionales</div>
    </div>
    <div class="row">
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 text-center">
                    <strong>Indicadores de Artículos</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-sm table-striped">
                        <tbody>
                        <tr style="border-left: 3px solid rgb(91,155,213)"><td>Autores conjuntos</td></tr>
                        <tr style="border-left: 3px solid rgb(237,125,49)"><td>Filiaciones conjuntas</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Autores y filiaciones</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Autores y revistas</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>Palabras Claves conjuntas</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>MeSH conjuntos</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CoWord títulos</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CoWord resumenes</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CoWord títulos y resumenes</td></tr>
                        <tr style="border-left: 3px solid rgb(255,192,0)"><td>Autores y materia</td></tr>
                        <tr style="border-left: 3px solid rgb(255,192,0)"><td>Autores y palabras claves</td></tr>
                        <tr style="border-left: 3px solid rgb(255,192,0)"><td>Autores y MeSH</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6">
            <div class="row">
                <div class="col-12 text-center">
                    <strong>Indicadores de Patentes</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-sm table-striped">
                        <tbody>
                        <tr style="border-left: 3px solid rgb(91,155,213)"><td>Inventores conjuntos</td></tr>
                        <tr style="border-left: 3px solid rgb(237,125,49)"><td>Titulares conjuntos</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Titulares e inventores</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Titulares y países</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Inventores y países</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Países [Titulares] conjuntos</td></tr>
                        <tr style="border-left: 3px solid rgb(112,48,160)"><td>Países [Inventores] conjuntos</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CIP conjunta</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CCP conjunta</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CoWord títulos</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CoWord resumenes</td></tr>
                        <tr style="border-left: 3px solid rgb(112,173,71)"><td>CoWord títulos y resumenes</td></tr>
                        <tr style="border-left: 3px solid rgb(255,192,0)"><td>Titulares y CIP</td></tr>
                        <tr style="border-left: 3px solid rgb(255,192,0)"><td>Inventores y CIP</td></tr>
                        <tr style="border-left: 3px solid rgb(255,192,0)"><td>Titulares y CCP</td></tr>
                        <tr style="border-left: 3px solid rgb(255,192,0)"><td>Inventores y CCP</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
