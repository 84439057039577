import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {FrequencyChart} from '@app/shared/model/metrics/frequency-chart';
import {BehaviorSubject} from 'rxjs';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {IPCDescription} from '@app/shared/model/metrics/ipc-description';
import {CPCDescription} from '@app/shared/model/metrics/cpc-description';
import {FrequencyForm} from '@app/shared/model/metrics/frequency-form';
import {ArticleFolderStoreService} from '@app/shared/folder/article-folder-store.service';
import {PatentFolderStoreService} from '@app/shared/folder/patent-folder-store.service';

@Injectable({
    providedIn: 'root'
})
export class FrequencyStoreService extends Store<FrequencyChart> {
    private observableForm: BehaviorSubject<FrequencyForm> = new BehaviorSubject<FrequencyForm>({
        indcode: '',
        yearMin: 2015,
        yearMax: parseInt(new Date().toISOString().split('-')[0], 10),
        filter: '',
        top: '',
        isLast: false,
        color: '#00ccbb',
        type: 'horizontalBar',
        equationAsStopWord: false,
        useFolderIds: false,
        folderIds: [],
        subject: ''
    });
    readonly observableForm$ = this.observableForm.asObservable();

    private observableIPCDescription: BehaviorSubject<IPCDescription[]> = new BehaviorSubject<IPCDescription[]>([]);
    readonly observableIPCDescription$ = this.observableIPCDescription.asObservable();

    private observableCPCDescription: BehaviorSubject<CPCDescription[]> = new BehaviorSubject<CPCDescription[]>([]);
    readonly observableCPCDescription$ = this.observableCPCDescription.asObservable();

    private observableSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    readonly oobservableSubject$ = this.observableSubject.asObservable();

    constructor(private ds: DataService,
                private ns: NotificatorService,
                private afss: ArticleFolderStoreService,
                private pfss: PatentFolderStoreService) {
        super();
        const frequencyChart: FrequencyChart = {
            labels: [],
            datasets: []
        };
        this.observableValue.next(frequencyChart);
        this.getSubjects();
    }

    getSubjects(): void {
        this.loading.next(true);
        this.ds.getSubjects().subscribe(
            res => {
                if (res.success === true) {
                    this.observableForm.next({
                        indcode: '',
                        yearMin: 2015,
                        yearMax: parseInt(new Date().toISOString().split('-')[0], 10),
                        filter: '',
                        top: '',
                        isLast: false,
                        color: '#00ccbb',
                        type: 'horizontalBar',
                        equationAsStopWord: false,
                        useFolderIds: false,
                        folderIds: [],
                        subject: res.data[0]
                    });
                    this.observableSubject.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    getFrecuencyIndicator(body): void {
        this.loading.next(true);
        this.observableForm.next({
            indcode: body.indcode,
            yearMin: body.yearMin,
            yearMax: body.yearMax,
            filter: body.filter,
            top: body.top,
            isLast: body.isLast,
            color: body.color,
            type: body.type,
            equationAsStopWord: body.equationAsStopWord,
            useFolderIds: body.useFolderIds,
            folderIds: body.folderIds,
            subject: body.subject
        });
        this.observableValue.next({labels: [], datasets: []});
        body.folderIds = [];
        if (body.useFolderIds == true) {
            if (body.indcode.includes('ART')) {
                this.afss.getFolder().map(a => {
                    body.folderIds.push(a.id);
                });
            } else {
                this.pfss.getFolder().map(p => {
                    body.folderIds.push(p.id);
                });
            }
        }
        this.ds.getFrecuencyIndicator(body).subscribe(
            res => {
                if (res.success == true) {
                    if (res.data.labels.length == 0) {
                        this.ns.sendNotification({type: 'warning', message: 'No hubo resultados'});
                    } else {
                        res.data.datasets[0].lineTension = 0.2;
                        res.data.datasets[0].borderWidth = 1;
                        res.data.datasets[0].borderColor = FrequencyStoreService.hexToRgbA(body.color);
                        res.data.datasets[0].backgroundColor = FrequencyStoreService.hexToRgbA(body.color).slice(0, FrequencyStoreService.hexToRgbA(body.color).length - 2) + '0.2)';
                        this.observableValue.next(res.data);
                        this.updateClasificationLegend();
                    }
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    private updateClasificationLegend() {
        if (this.observableForm.getValue().indcode.includes('xIPC') && this.observableValue.getValue().labels.length > 0) {
            this.ds.getIPCDescription(this.observableValue.getValue().labels).subscribe(
                res => {
                    if (res.success === true) {
                        this.observableIPCDescription.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'warning', message: res.error});
                    }
                },
                error => this.ns.sendNotification({type: 'error', message: error.message})
            );
        } else {
            this.observableIPCDescription.next([]);
        }

        if (this.observableForm.getValue().indcode.includes('xCPC') && this.observableValue.getValue().labels.length > 0) {
            this.ds.getCPCDescription(this.observableValue.getValue().labels).subscribe(
                res => {
                    if (res.success === true) {
                        this.observableCPCDescription.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'warning', message: res.error});
                    }
                },
                error => this.ns.sendNotification({type: 'error', message: error.message})
            );
        } else {
            this.observableCPCDescription.next([]);
        }
    }

    private static hexToRgbA(hex): string {
        let c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
        }
        throw new Error('Bad Hex');
    }

}
