import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Metadata} from '@app/shared/model/vigilance/metadata';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {VigilanceConfig} from '@app/shared/model/vigilance/vigilance-config';

@Injectable({
    providedIn: 'root'
})
export class VigilanceStoreService extends Store<Metadata> {
    private observableVigilanceConfig: BehaviorSubject<VigilanceConfig> = new BehaviorSubject<VigilanceConfig>({
        newsActive: false,
        sicNewsLettersActive: false
    });
    readonly observableVigilanceConfig$: Observable<VigilanceConfig> = this.observableVigilanceConfig.asObservable();


    constructor(private ds: DataService,
                private ns: NotificatorService) {
        super();
        const metadata: Metadata = {
            patents: 0,
            articles: 0,
            journals: 0,
            last_articles: 0,
            last_patents: 0,
            article_last_updates: [],
            patent_last_updates: []
        };
        this.observableValue.next(metadata);
        this.getMetadata();
        this.getVigilanceConfig();



    }

    private getVigilanceConfig() {
        this.ds.observableClientConfig$.subscribe(
            res => this.observableVigilanceConfig.next(res.vigilance)
        );
    }

    public getMetadata() {
        this.loading.next(true);
        this.ds.getMetadata().subscribe(
            res => {
                if (res.success == true) {
                    this.observableValue.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }
}
