<nav class="navbar navbar-expand-lg navbar-light sticky-top" style="background-color: #f1f1f1;">
    <span  class="navbar-brand mb-0 h1">
        <img src="assets/img/logo.png" style="height: 45px" alt="">
    </span>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarColor01">
        <ul class="navbar-nav ml-auto d-flex align-items-center">
            <li class="nav-item">
                <a class="nav-link" routerLink="//home" routerLinkActive="active"><strong style="color: {{(config$|async).menuColor}}">Inicio</strong></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="//about" routerLinkActive="active"><strong style="color: {{(config$|async).menuColor}}">Acerca de</strong></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="//metasearcher" routerLinkActive="active">
                    <img class="ml-1" src="assets/img/{{(home$|async).metasearcher.image}}" alt="" title="{{(home$|async).metasearcher.name}}" style="height: 20px"> <strong style="color: {{(config$|async).menuColor}}"> {{(home$|async).metasearcher.name}}</strong>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="//metrics" routerLinkActive="active">
                    <img class="ml-1" src="assets/img/{{(home$|async).metrics.image}}" alt="" title="{{(home$|async).metrics.name}}" style="height: 20px"> <strong style="color: {{(config$|async).menuColor}}"> {{(home$|async).metrics.name}}</strong>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="//vigilance" routerLinkActive="active">
                    <img class="ml-1" src="assets/img/{{(home$|async).vigilance.image}}" alt="" title="{{(home$|async).vigilance.name}}" style="height: 20px"> <strong style="color: {{(config$|async).menuColor}}"> {{(home$|async).vigilance.name}}</strong>
                </a>
            </li>
            <li class="nav-item" *ngIf="haveSessionValue()">
                <a class="nav-link" routerLink="//folder" routerLinkActive="active"><strong style="color: {{(config$|async).menuColor}}"><i class="fas fa-folder fa-2x"></i></strong></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="//help" routerLinkActive="active"><strong style="color: {{(config$|async).menuColor}}">Ayuda!</strong></a>
            </li>
            <li class="nav-item">
                <span class="nav-link" style="cursor: pointer" (click)="$event.preventDefault();showLogin=!showLogin;">
                    <strong style="color: {{(config$|async).menuColor}}"><i class="fas fa-user-circle"></i></strong>
                </span>
            </li>
        </ul>
    </div>
</nav>
<div class="jumbotron py-2 disable-select loginwindow" [ngClass]="(showLogin==true)?'d-block':'d-none'">
    <div class="row d-flex justify-content-end" style="cursor:pointer">
        <span (click)="showLogin=false;"><i class="fas fa-window-close"></i></span>
    </div>
    <div class="row">
        <div class="col text-center">
            <img class="img-fluid" src="assets/img/logo.png" style="height: 50px">
        </div>
    </div>
    <div *ngIf="(user$|async).username == ''">
        <div class="row">
            <div class="col text-center">
                <strong>Administrar</strong>
            </div>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="row mt-2" style="position: relative">
                <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
                    <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                         role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="row-form mt-1">
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fas fa-user text-success"></i></div>
                    </div>
                    <input type="text" class="form-control" id="username" formControlName="username" placeholder="Usuario"
                        minlength="3" maxlength="30">
                </div>
            </div>
            <div class="row-form mt-1">
                <div class="input-group mb-2">
                    <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fas fa-key text-success"></i></div>
                    </div>
                    <input type="password" class="form-control" id="password" formControlName="password" placeholder="Contraseña"
                           minlength="4" maxlength="50">
                </div>
            </div>
            <div class="row-form mt-1">
                <button class="btn btn-outline-success col" type="submit"
                        [disabled]="loginForm.invalid || (loading$ | async) === true">Entrar</button>
            </div>
        </form>
    </div>
    <div *ngIf="(user$|async).username !== ''">
        <div class="row">
            <div class="col text-center">
                <strong>{{(user$|async).fullname}}</strong>
            </div>
        </div>
        <div class="row-form mt-1">
            <button class="btn btn-outline-success col" type="submit" (click)="logout()">Salir</button>
        </div>
    </div>
</div>
