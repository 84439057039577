import { Component, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {Statistic} from '@app/shared/model/vigilance/statistic';
import {StatisticsStoreService} from '@app/vigilance/store/statistics-store.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
    statistic$:Observable<Statistic>;
    loading$:Observable<boolean>;
  constructor(private sss:StatisticsStoreService) { }

  ngOnInit(): void {
      this.statistic$ = this.sss.observableValue$;
      this.loading$ = this.sss.loading$;
      this.sss.getStatistics();
  }

    objectKeys(obj: any) {
        return Object.keys(obj);
    }
}
