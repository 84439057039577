<div class="container-fluid">
    <div class="row">
        <div class="col-12 text-right">
            <a href="/api/journals"><i class="fa fa-download"></i>&nbsp;Metadatos de Revistas</a>
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                 role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <h3>Artículos</h3>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Tema</th>
                    <th scope="col">Cantidad</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let k of objectKeys((statistic$|async).articles)">
                    <td>{{k}}</td>
                    <td>{{(statistic$|async).articles[k]}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col-12 col-md-6">
            <h3>Patentes</h3>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Tema</th>
                    <th scope="col">Cantidad</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let k of objectKeys((statistic$|async).patents)">
                    <td>{{k}}</td>
                    <td>{{(statistic$|async).patents[k]}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
