<div class="container mt-2">
    <div class="row mt-2" *ngIf="(loading$ | async) === true">
        <div class="col-12 text-center">
            <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="(loading$ | async) === false">
        <div class="col-1" >
            <button type="button" (click)="goBack()" class="btn btn-outline-info"><i class="fa fa-angle-double-left" aria-hidden="true"></i> regresar</button>
        </div>
        <div class="col-12 col-md-11 text-center">
            <h2>{{(personProfile$| async).name}}</h2>
            <h5 class="d-flex d-inline-flex">{{(personProfile$| async).countryName}}</h5>&nbsp;&nbsp;<img src="assets/img/Flags/{{(personProfile$| async).countryCode}}.PNG" alt="">
        </div>
    </div>
    <div class="row mt-2" *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>{{assigneeTitle}}</h4>
        </div>
        <div class="col-12">
            <app-chart-card [data]="(personProfile$|async).assignees" type="horizontalBar"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>Gremio de Inventores</h4>
        </div>
        <div class="col-12">
            <app-chart-card [data]="(personProfile$|async).inventors" type="horizontalBar"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>Clasificación Internacional de Patentes</h4>
        </div>
        <div class="col-12" *ngIf="(loading$ | async) === false">
            <app-chart-card [data]="(personProfile$|async).ipcs" type="horizontalBar"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>Clasificación Conjunta de Patentes</h4>
        </div>
        <div class="col-12" *ngIf="(loading$ | async) === false">
            <app-chart-card [data]="(personProfile$|async).cpcs" type="horizontalBar"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>Patentes</h4>
        </div>
        <div class="col-12" *ngFor="let e of (personProfile$ | async).patents">
            <a href="#" routerLink="//metasearcher/patent/{{e.id}}">{{e.CC}}{{e.PN}}{{e.KD}} - {{e.ISD}} {{e.TTL}}</a>
        </div>
    </div>
</div>

