import {Injectable} from '@angular/core';
import {Notification} from './notification';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotificatorService {
    private subject = new Subject<Notification>();

    constructor() {
    }

    sendNotification(notification: Notification) {
        this.subject.next(notification);
    }

    getNotification(): Observable<Notification> {
        return this.subject.asObservable();
    }
}
