<div class="container-fluid">
    <div class="row mt-2">
        <div class="col-12">
            <h4 class="text-center">Últimas Patentes <small *ngIf="(count$|async) != 0">({{(count$|async)}})</small></h4>
            <h6 class="text-center small">*Patentes que entraron al observatorio en la última actualización</h6>
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                 role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(patents$ | async).length > 0">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2" *ngFor="let p of (patents$ | async)" [@simpleFadeAnimation]="'in'">
            <div class="card mt-1 shadow" routerLink="//metasearcher/patent/{{p.id}}" style="cursor: pointer">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <span *ngFor="let o of p.offices; index as i"><span *ngIf="i>0"> ,</span>{{o}}</span>
                        </div>
                        <div class="col-6 text-right">
                            {{p.CC}}{{p.PN}}{{p.KD}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                           <h6 class="text-center"><strong>{{p.TTL}}</strong></h6>
                        </div>
                    </div>
                    <div class="row" *ngIf="p.ABST != ''">
                        <div class="col-12 text-center"><strong>Resumen</strong></div>
                        <div class="col-12 small text-justify">{{p.ABST.slice(0,200)}}...</div>
                    </div>
                    <hr>
                    <div class="col-12" *ngIf="p.Inventors.length>0">
                        <strong>Inventor: </strong>{{p.Inventors[0].IN}}
                        <span *ngIf="p.Inventors.length > 1">et, al.</span>
                    </div>
                    <div class="col-12" *ngIf="p.Assignees.length>0">
                        <strong>Titular: </strong>{{p.Assignees[0].AN}}
                        <span *ngIf="p.Assignees.length > 1">et, al.</span>
                    </div>
                    <div class="col-12 text-right">
                        <span *ngFor="let c of p.IPCs"> {{c}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
