import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {About} from '@app/shared/model/about/about';
import {DataService} from '@app/shared/data/data.service';

@Injectable({
    providedIn: 'root'
})
export class AboutStoreService extends Store<About> {

    constructor(private ds: DataService) {
        super();
        const about: About = {
            firsts: [],
            items: [],
            lasts: []
        };
        this.observableValue.next(about);
        this.getAboutConfig();
    }

    getAboutConfig() {
        this.ds.observableClientConfig$.subscribe(
            res => this.observableValue.next(res.about)
        );
    }
}
