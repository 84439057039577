<div class="container">
    <div class="row mt-2">
        <div class="col-12">
            <div class="card shadow">
                <div class="card-header bg-info text-light">
                    Panel de Control
                </div>
                <div class="card-body">
                    <form [formGroup]="frequencyForm" (ngSubmit)="onSubmit()">
                        <div class="form-row align-items-center">
                            <div class="form-group col-12 col-md-4">
                                <label for="indcode">Indicador:&nbsp;</label>
                                <select name="indcode" id="indcode" class="form-control"
                                        formControlName="indcode">
                                    <option value=""></option>
                                    <option value="ARTxYear">Producción(artículos) por años</option>
                                    <option value="ARTxAN">Producción por autores</option>
                                    <option value="ART_CLxAN">Cantidad de colegas por autores</option>
                                    <option value="ARTxAAN">Producción por filiaciones</option>
                                    <option value="ARTxJTTL">Producción por revistas</option>
                                    <option value="ARTxSUB">Producción por materias</option>
                                    <ng-container *ngIf="(user$|async).username !== ''">
                                        <option value="ARTxKWRD">Producción por palabras clave</option>
                                        <option value="ARTxMeSH">Producción por MeSH</option>
                                    </ng-container>
                                    <hr>
                                    <option value="PATxYear">Producción(patentes) por años</option>
                                    <option value="PATxACN">Producción por país del titular</option>
                                    <option value="PATxICN">Producción por país del inventor</option>
                                    <option value="PATxAN">Producción por titulares</option>
                                    <option value="PATxIN">Producción por inventores</option>
                                    <option value="PATxIPCSC">Producción por CIP[subclase]</option>
                                    <option value="PATxCPCSC">Producción por CCP[subclase]</option>
                                </select>
                            </div>
                            <div class="form-group col-12 col-md-2">
                                <label for="yearMin">&nbsp;Entre los años:&nbsp;{{frequencyForm.value.yearMin}}</label>
                                <input id="yearMin" type="range" min="2000" max="{{frequencyForm.value.yearMax}}"
                                       class="form-control"
                                       formControlName="yearMin">
                            </div>
                            <div class="form-group col-12 col-md-2">
                                <label for="yearMax">&nbsp;y:&nbsp;{{frequencyForm.value.yearMax}}</label>
                                <input id="yearMax" type="range" min="{{frequencyForm.value.yearMin}}" max="{{currentYear}}"
                                       class="form-control"
                                       formControlName="yearMax">
                            </div>
                            <div class="form-group col-12 col-md-2" placement="top" ngbTooltip="Usa solo documentos del top selecionado">
                                <label for="top">Top 10:&nbsp;</label>
                                <select name="top" id="top" class="form-control"
                                        formControlName="top">
                                    <option value=""></option>
                                    <option value="author">Autores</option>
                                    <option value="colleague">Colegas</option>
                                    <option value="keyword">Palabras Clave</option>
                                    <option value="MeSH">MeSH</option>
                                </select>
                            </div>
                            <div class="form-group col-12 col-md-2">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Usa solo los documentos de la última actualización">
                                    <input class="form-check-input" name="isLast" type="checkbox" id="isLast"
                                           formControlName="isLast">
                                    <label class="form-check-label" for="isLast">
                                        Últimos Documentos
                                    </label>
                                </div>
                                <ng-container *ngIf="frequencyForm.value.isLast == true">
                                    <div class="row mt-2" style="position: relative">
                                        <div class="col-12 text-center"
                                             style="position: absolute; top: 0; z-index: 999">
                                            <div *ngIf="(loadingMetadata$|async)===true"
                                                 class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                                                 role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col small">
                                        <strong>Artículos:</strong> {{(metadata$ | async).last_articles}}</div>
                                    <div class="col small">
                                        <strong>Patentes:</strong> {{(metadata$ | async).last_patents}}</div>
                                    <div class="col small" *ngFor="let u of (metadata$ | async).article_last_updates">
                                        <strong>{{u.name}}:</strong> {{u.date}}</div>
                                    <div class="col small" *ngFor="let u of (metadata$ | async).patent_last_updates">
                                        <strong>{{u.name}}:</strong> {{u.date}}</div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="subject">Tema:</label>
                                <select class="form-control"
                                        formControlName="subject"
                                        id="subject">
                                    <option *ngFor="let d of (subjects$ | async)" value="{{d}}">{{d}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label for="filter">Filtro:</label>
                                <input id="filter" type="text" class="form-control" formControlName="filter">
                            </div>
                        </div>
                        <div class="form-row align-items-center">
                            <div class="form-group col-12 col-md-3"  placement="top" ngbTooltip="Tipo de gráfico">
                                <label for="type">Tipo:&nbsp;</label>
                                <select name="type" id="type" class="form-control"
                                        formControlName="type">
                                    <option value="line">Línea</option>
                                    <option value="horizontalBar">Barras Horizontales</option>
                                    <option value="bar">Barras Verticales</option>
                                </select>
                            </div>
                            <div class="form-group col-12 col-md-1"  placement="top" ngbTooltip="Color del gráfico">
                                <label for="color">Color:</label>
                                <input id="color" type="color" class="form-control" formControlName="color">
                            </div>
                            <div class="form-group col-12 col-md-2">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Evita que aparezcan en el grafo los términos usados en el tema">
                                    <input class="form-check-input" name="equationAsStopWord" type="checkbox"
                                           id="equationAsStopWord" formControlName="equationAsStopWord">
                                    <label class="form-check-label" for="equationAsStopWord">
                                        Ecuación como Stopwords
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-2">
                                &nbsp;
                                <div class="form-check" placement="top" ngbTooltip="Usa solo documentos de 'Mi Carpeta'">
                                    <input class="form-check-input" name="useFolderIds" type="checkbox"
                                           id="useFolderIds" formControlName="useFolderIds">
                                    <label class="form-check-label" for="useFolderIds">
                                        Usar solo "Mi Carpeta"
                                    </label>
                                </div>
                            </div>
                            <div class="form-group col-12 col-md-4">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-outline-success" type="submit"
                                            [disabled]="frequencyForm.invalid || (loading$ | async) === true">Graficar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                 role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(frequencyChart$|async).labels.length>0">
        <div class="col-12">
            <app-chart-card [indcode]="(frequencyForm.value.indcode)" [data]="(frequencyChart$|async)" [type]="(frequencyForm$|async).type"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12" *ngIf="(IPCDescription$|async).length > 0">
            <div class="card shadow">
                <div class="card-body">
                    <div class="card-title">
                        Leyenda:
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-ipc-legend [legend]="(IPCDescription$|async)"></app-ipc-legend>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="(CPCDescription$|async).length > 0">
            <div class="card shadow">
                <div class="card-body">
                    <div class="card-title">
                        Leyenda:
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <app-cpc-legend [legend]="(CPCDescription$|async)"></app-cpc-legend>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
