import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Footer} from '@app/shared/model/footer/footer';
import {FooterStoreService} from '@app/core/store/footer-store.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    footer$: Observable<Footer>;

    constructor(private fss: FooterStoreService) {
    }

    ngOnInit() {
        this.footer$ = this.fss.observableValue$;
    }

}
