import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {PersonProfile} from '@app/shared/model/profile/person-profile';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {ProfileStoreService} from '@app/meta-searcher/store/profile-store.service';

@Component({
    selector: 'app-sci-profile-view',
    templateUrl: './sci-profile-view.component.html',
    styleUrls: ['./sci-profile-view.component.scss']
})
export class SciProfileViewComponent implements OnInit {
    personProfile$: Observable<PersonProfile>;
    loading$: Observable<boolean>;

    constructor(private location: Location,
                private route: ActivatedRoute,
                private apss: ProfileStoreService) {
        route.params.subscribe(params => {
            this.apss.getAuthorProfile(parseInt(params.id));
        });
    }

    ngOnInit(): void {
        this.personProfile$ = this.apss.observableValue$;
        this.loading$ = this.apss.loading$;
    }

    goBack(): void {
        this.location.back();
    }
}
