import {Component, Input, OnInit} from '@angular/core';
import {IPCDescription} from '../../shared/model/metrics/ipc-description';

@Component({
    selector: 'app-ipc-legend',
    templateUrl: './ipc-legend.component.html',
    styleUrls: ['./ipc-legend.component.css']
})
export class IPCLegendComponent implements OnInit {
    @Input() legend: IPCDescription[];

    constructor() {
    }

    ngOnInit() {
    }

    colorize(kind: string): string {
        let textClass = '';
        switch (kind) {
            case 's':
                textClass = 'text-danger';
                break;
            case 'c':
                textClass = 'text-warning';
                break;
            case 'u':
                textClass = 'text-success';
                break;
            case 'm':
                textClass = 'text-primary';
                break;
            default:
                textClass = 'text-info';
                break;
        }
        return textClass;
    }
}
