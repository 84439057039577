import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {FrequencyChart} from '@app/shared/model/metrics/frequency-chart';
import {FrequencyStoreService} from '@app/metrics/store/frequency-store.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IPCDescription} from '@app/shared/model/metrics/ipc-description';
import {CPCDescription} from '@app/shared/model/metrics/cpc-description';
import {FrequencyForm} from '@app/shared/model/metrics/frequency-form';
import {VigilanceStoreService} from '@app/vigilance/store/vigilance-store.service';
import {Metadata} from '@app/shared/model/vigilance/metadata';
import {User} from '@app/shared/model/core/user';
import {CoreStoreService} from '@app/core/store/core-store.service';

@Component({
    selector: 'app-frequency',
    templateUrl: './frequency.component.html',
    styleUrls: ['./frequency.component.scss']
})
export class FrequencyComponent implements OnInit {
    frequencyChart$: Observable<FrequencyChart>;
    frequencyForm: FormGroup;
    frequencyForm$: Observable<FrequencyForm>;// for chart type only
    loading$: Observable<boolean>;
    IPCDescription$: Observable<IPCDescription[]>;
    CPCDescription$: Observable<CPCDescription[]>;
    metadata$: Observable<Metadata>;
    loadingMetadata$: Observable<boolean>;
    user$: Observable<User>;
    subjects$: Observable<string[]>;
    currentYear = parseInt(new Date().toISOString().split('-')[0], 10);

    constructor(private fss: FrequencyStoreService,
                private fb: FormBuilder,
                private vss: VigilanceStoreService,
                private css: CoreStoreService) {
    }

    ngOnInit(): void {
        this.frequencyChart$ = this.fss.observableValue$;
        this.frequencyForm$ = this.fss.observableForm$;
        this.loading$ = this.fss.loading$;
        this.IPCDescription$ = this.fss.observableIPCDescription$;
        this.CPCDescription$ = this.fss.observableCPCDescription$;
        this.metadata$ = this.vss.observableValue$;
        this.loadingMetadata$ = this.vss.loading$;
        this.user$ = this.css.observableUser$;
        this.fss.observableForm$.subscribe(
            res => {
                this.frequencyForm = this.fb.group({
                    indcode: [res.indcode, [Validators.required]],
                    yearMin: [res.yearMin],
                    yearMax: [res.yearMax],
                    filter: [res.filter],
                    top: [res.top],
                    isLast: [res.isLast],
                    color: [res.color],
                    type: [res.type],
                    equationAsStopWord: [res.equationAsStopWord],
                    useFolderIds: [res.useFolderIds],
                    folderIds: [res.folderIds],
                    subject: [res.subject]
                });
            }
        );
        this.subjects$ = this.fss.oobservableSubject$;
    }

    onSubmit(): void {
        this.fss.getFrecuencyIndicator(this.frequencyForm.value);
    }
}
