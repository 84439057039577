<div class="container-fluid">
    <div class="row mt-2">
        <div class="col-12">
            <h4 class="text-center">Boletines Vigintel</h4>
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row mt-2" *ngIf="(newsletters$ | async).length > 0">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-2" *ngFor="let n of (newsletters$ | async)" [@simpleFadeAnimation]="'in'">
            <div class="card mt-1 shadow">
                <img style="width: 100%; height: auto" src="{{n.image}}" alt="{{n.image}}">
                <div class="card-body">
                    <blockquote class="blockquote text-justify ">
                        <h5 style="font-size: 0.7em" class="text-justify">{{n.title}}</h5>
                        <p style="font-size: 0.7em" class="text-justify">{{n.text}}</p>
                        <footer class="text-right small">{{n.date}}</footer>
                        <footer class="text-right"><a href="{{n.fileName}}">Leer más</a></footer>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</div>
