import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {BasicHttpResponse} from '@app/shared/model/basic-http-response';
import {Patent} from '@app/shared/model/patent/patent';
import {Article} from '@app/shared/model/article/article';
import {NewsItem} from '@app/shared/model/vigilance/news-item';
import {SearchResult} from '@app/shared/model/search/search-result';
import {Metadata} from '@app/shared/model/vigilance/metadata';
import {FrequencyChart} from '@app/shared/model/metrics/frequency-chart';
import {EncodeUriService} from '@app/shared/encode-uri/encode-uri.service';
import {IPCDescription} from '@app/shared/model/metrics/ipc-description';
import {CPCDescription} from '@app/shared/model/metrics/cpc-description';
import {Map} from '@app/shared/model/metrics/map';
import {User} from '@app/shared/model/core/user';
import {PersonProfile} from '@app/shared/model/profile/person-profile';
import {VigilanceConfig} from '@app/shared/model/vigilance/vigilance-config';
import {NewsLetter} from '@app/shared/model/vigilance/news-letter';
import {Statistic} from '@app/shared/model/vigilance/statistic';
import {ArticleChunk} from '@app/shared/model/search/article-chunk';
import {PatentChunk} from '@app/shared/model/search/patent-chunk';
import {ClientConfig} from '@app/shared/model/client-config';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    private observableClientConfig: BehaviorSubject<ClientConfig> = new BehaviorSubject<ClientConfig>({
        about: {
            firsts: [],
            lasts: [],
            items: []
        },
        core: {
            name: '',
            menuColor: ''
        },
        footer: {
            prefooter:false,
            sponsor1: {
                url: "",
                title: "",
                lines: []
            },
            sponsor2: {
                url: "",
                title: "",
                lines: []
            },
            contacts: [],
            interestLinks: []
        },
        home: {
            vigilance: {
                name: '',
                image: '',
                description: ''
            },
            metrics: {
                name: '',
                image: '',
                description: ''
            },
            toolsNameColor: '',
            extTool: {
                name: '',
                image: '',
                description: '',
                url: '',
                activated: false
            },
            metasearcher: {
                name: '',
                image: '',
                description: ''
            }
        },
        vigilance: {
            newsActive: false,
            sicNewsLettersActive: false
        },
        whoweare: {
            content: [],
            tech: [],
            aknowledgements: []
        }
    });
    readonly observableClientConfig$: Observable<ClientConfig> = this.observableClientConfig.asObservable();

    constructor(private httpClient: HttpClient) {
        fetch('./client.json').then(resp => resp.json().then(o => this.observableClientConfig.next(o)));
    }

// login
    login(body): Observable<BasicHttpResponse<User>> {
        return this.httpClient.post<BasicHttpResponse<User>>('/api/login', body);
    }

    logout(): Observable<BasicHttpResponse<boolean>> {
        return this.httpClient.get<BasicHttpResponse<boolean>>('/api/logout');
    }

    haveSession(): Observable<BasicHttpResponse<User>> {
        return this.httpClient.get<BasicHttpResponse<User>>('/api/haveSession');
    }

//vigilance
    getVigilanceConfig(): Observable<BasicHttpResponse<VigilanceConfig>> {
        return this.httpClient.get<BasicHttpResponse<VigilanceConfig>>('/api/client/config/vigilance');
    }

    getLastArticles(): Observable<BasicHttpResponse<Article[]>> {
        return this.httpClient.get<BasicHttpResponse<Article[]>>('/api/getLastArticles');
    }

    getLastArticlesCount(): Observable<BasicHttpResponse<number>> {
        return this.httpClient.get<BasicHttpResponse<number>>('/api/getLastArticlesCount');
    }

    getLastPatents(): Observable<BasicHttpResponse<Patent[]>> {
        return this.httpClient.get<BasicHttpResponse<Patent[]>>('/api/getLastPatents');
    }

    getLastPatentsCount(): Observable<BasicHttpResponse<number>> {
        return this.httpClient.get<BasicHttpResponse<number>>('/api/getLastPatentsCount');
    }

    getNews(): Observable<BasicHttpResponse<NewsItem[]>> {
        return this.httpClient.get<BasicHttpResponse<NewsItem[]>>('/api/news');
    }

    getNewsLetters(): Observable<BasicHttpResponse<NewsLetter[]>> {
        return this.httpClient.get<BasicHttpResponse<NewsLetter[]>>('/api/newsLetters');
    }

    getMetadata(): Observable<BasicHttpResponse<Metadata>> {
        return this.httpClient.get<BasicHttpResponse<Metadata>>(`/api/metadata`);
    }

    getStatistic(): Observable<BasicHttpResponse<Statistic>> {
        return this.httpClient.get<BasicHttpResponse<Statistic>>(`/api/statistics`);
    }

//metasearcher
    search(body): Observable<BasicHttpResponse<SearchResult>> {
        return this.httpClient.post<BasicHttpResponse<SearchResult>>('/api/search', body);
    }

    getSubjects(): Observable<BasicHttpResponse<string[]>> {
        return this.httpClient.get<BasicHttpResponse<string[]>>(`/api/getSubjects`);
    }

    getArticle(id: number): Observable<BasicHttpResponse<Article>> {
        return this.httpClient.get<BasicHttpResponse<Article>>(`/api/article/${id}`);
    }

    getPatent(id: number): Observable<BasicHttpResponse<Patent>> {
        return this.httpClient.get<BasicHttpResponse<Patent>>(`/api/patent/${id}`);
    }

    getAssigneeProfile(id: number): Observable<BasicHttpResponse<PersonProfile>> {
        return this.httpClient.get<BasicHttpResponse<PersonProfile>>(`/api/AssigneeProfileProvider?id=${id}`);
    }

    getInventorProfile(id: number): Observable<BasicHttpResponse<PersonProfile>> {
        return this.httpClient.get<BasicHttpResponse<PersonProfile>>(`/api/InventorProfileProvider?id=${id}`);
    }

    getAuthorProfile(id: number): Observable<BasicHttpResponse<PersonProfile>> {
        return this.httpClient.get<BasicHttpResponse<PersonProfile>>(`/api/AuthorProfileProvider?id=${id}`);
    }

//Metrics
    getFrecuencyIndicator(body): Observable<BasicHttpResponse<FrequencyChart>> {
        return this.httpClient.post<BasicHttpResponse<FrequencyChart>>('/api/frequency', body);
    }

    getMapIndicator(body): Observable<BasicHttpResponse<Map>> {
        return this.httpClient.post<BasicHttpResponse<Map>>('/api/map', body);
    }

// clasifications
    getIPCDescription(symbols: string[]): Observable<BasicHttpResponse<IPCDescription[]>> {
        return this.httpClient.get<BasicHttpResponse<IPCDescription[]>>(`/api/getClassificationDescription?symbols=${EncodeUriService.encodeUriComponent(symbols)}&type=ipc`);
    }

    getCPCDescription(symbols: string[]): Observable<BasicHttpResponse<CPCDescription[]>> {
        return this.httpClient.get<BasicHttpResponse<CPCDescription[]>>(`/api/getClassificationDescription?symbols=${EncodeUriService.encodeUriComponent(symbols)}&type=cpc`);
    }

// MyFolder
    getArticles(): Observable<BasicHttpResponse<ArticleChunk[]>> {
        return this.httpClient.get<BasicHttpResponse<ArticleChunk[]>>(`/api/myfolder/getArticles`);
    }

    getPatents(): Observable<BasicHttpResponse<PatentChunk[]>> {
        return this.httpClient.get<BasicHttpResponse<PatentChunk[]>>(`/api/myfolder/getPatents`);
    }

    addDocument(id, type): Observable<BasicHttpResponse<null>> {
        return this.httpClient.post<BasicHttpResponse<null>>('/api/myfolder/addDocument', {id, type});
    }

    removeDocument(id, type): Observable<BasicHttpResponse<null>> {
        return this.httpClient.post<BasicHttpResponse<null>>('/api/myfolder/removeDocument', {id, type});
    }
}
