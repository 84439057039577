import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {SearchResult} from '@app/shared/model/search/search-result';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {BehaviorSubject} from 'rxjs';
import {MetasearcherForm} from '@app/shared/model/search/metasearcher-form';
import {ArticleFolderStoreService} from '@app/shared/folder/article-folder-store.service';
import {PatentFolderStoreService} from '@app/shared/folder/patent-folder-store.service';
import {ArticleChunk} from '@app/shared/model/search/article-chunk';
import {PatentChunk} from '@app/shared/model/search/patent-chunk';

@Injectable({
    providedIn: 'root'
})
export class MetaSearcherStoreService extends Store<SearchResult> {
    private observableForm: BehaviorSubject<MetasearcherForm> = new BehaviorSubject<MetasearcherForm>({
        filter: '',
        authorFilter: '',
        yearFilter: '',
        indexname: 'sciIndex',
        subject: '',
        pageStart: 0
    });
    readonly observableForm$ = this.observableForm.asObservable();

    private observableSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    readonly oobservableSubject$ = this.observableSubject.asObservable();

    constructor(private ds: DataService,
                private ns: NotificatorService,
                private afss: ArticleFolderStoreService,
                private pfss: PatentFolderStoreService) {
        super();
        const searchResult: SearchResult = {
            time: 0,
            total_found: 0,
            data: []
        };
        this.observableValue.next(searchResult);
        this.getSubjects();
    }

    getLoadingValue(): boolean {
        return this.loading.getValue();
    }

    getResultCount(): number {
        return this.observableValue.getValue().data.length;
    }

    getSubjects(): void {
        this.loading.next(true);
        this.ds.getSubjects().subscribe(
            res => {
                if (res.success === true) {
                    this.observableForm.next({
                        filter: '',
                        authorFilter: '',
                        yearFilter: '',
                        indexname: 'sciIndex',
                        subject: res.data[0],
                        pageStart: 0
                    });
                    this.observableSubject.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    search(body: MetasearcherForm): void {
        this.loading.next(true);
        this.observableForm.next({
            filter: body.filter,
            indexname: body.indexname,
            subject: body.subject,
            authorFilter: body.authorFilter,
            yearFilter: body.yearFilter,
            pageStart: body.pageStart
        });
        if (body.pageStart == 0) {
            this.observableValue.next({
                time: 0, total_found: 0, data: []
            });
        }
        body.filter = body.filter.replace(/\sor\s/gi, ' | ').replace(/\sand\s/gi, ' ');
        if (body.authorFilter != '') {
            body.filter += ' (@AN ' + body.authorFilter.replace(/\sor\s/gi, ' | ').replace(/\sand\s/gi, ' ') + ')';
        }
        if (body.yearFilter != '') {
            body.filter += ' (@YEAR ' + body.yearFilter.replace(/\sor\s/gi, ' | ').replace(/\sand\s/gi, ' ') + ')';
        }
        this.ds.search(body).subscribe(
            res => {
                if (res.success == true) {
                    if (body.indexname == 'sciIndex') {
                        for (const idx in res.data.data) {
                            if (res.data.data.hasOwnProperty(idx)) {
                                const id = res.data.data[idx].id;
                                res.data.data[idx].inUserFolder = this.afss.containsId(id);
                            }
                        }
                    } else {
                        for (const idx in res.data.data) {
                            if (res.data.data.hasOwnProperty(idx)) {
                                const id = res.data.data[idx].id;
                                res.data.data[idx].inUserFolder = this.pfss.containsId(id);
                            }
                        }
                    }
                    let data: any = this.observableValue.getValue().data;
                    for (let i = 0; i < res.data.data.length; i++) {
                        let o = res.data.data[i];
                        data.push(o);
                    }
                    res.data.data = data;

                    this.observableValue.next(res.data);
                    if (res.data.total_found == 0) {
                        this.ns.sendNotification({type: 'warning', message: 'No hubo resultados'});
                    }
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }
}
