import {Component, Input, OnInit} from '@angular/core';
import {CPCDescription} from '../../shared/model/metrics/cpc-description';

@Component({
    selector: 'app-cpc-legend',
    templateUrl: './cpc-legend.component.html',
    styleUrls: ['./cpc-legend.component.css']
})
export class CPCLegendComponent implements OnInit {
    @Input() legend: CPCDescription[];

    constructor() {
    }

    ngOnInit() {
    }

    colorize(level: number): string {
        let textClass = '';
        switch (level) {
            case 2:
                textClass = 'text-danger';
                break;
            case 3:
            case 4:
                textClass = 'text-warning';
                break;
            case 5:
            case 6:
                textClass = 'text-success';
                break;
            case 7:
                textClass = 'text-primary';
                break;
            default:
                textClass = 'text-info';
                break;
        }
        return textClass;
    }
}
