import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Core} from '@app/shared/model/core/core';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '@app/shared/model/core/user';

@Injectable({
    providedIn: 'root'
})
export class CoreStoreService extends Store<Core> {
    private observableUser: BehaviorSubject<User> = new BehaviorSubject<User>({
        email: '',
        username: '',
        fullname: '',
        isAdmin: false,
        lastAction: ''
    });
    readonly observableUser$: Observable<User> = this.observableUser.asObservable();

    constructor(private ds: DataService,
                private ns: NotificatorService) {
        super();
        const core: Core = {
            name: '',
            menuColor: '#000000'
        };
        this.observableValue.next(core);
        this.getCoreConfig();
        this.haveSession();
    }

    getCoreConfig() {
        this.ds.observableClientConfig$.subscribe(
            res => this.observableValue.next(res.core)
        );
    }

    haveSession() {
        this.ds.haveSession().subscribe(
            res => {
                if (res.success == true) {
                    this.observableUser.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
            }
        );
    }

    haveSessionValue(){
        return this.observableUser.getValue();
    }

    login(body) {
        this.loading.next(true);
        this.ds.login(body).subscribe(
            res => {
                if (res.success == true) {
                    this.observableUser.next(res.data);
                    this.ns.sendNotification({type: 'login', message: ''});
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }

    logout() {
        this.ds.logout().subscribe(
            res => {
                if (res.success == true) {
                    this.observableUser.next({
                        email: '',
                        username: '',
                        fullname: '',
                        isAdmin: false,
                        lastAction: ''
                    });
                    this.ns.sendNotification({type: 'logout', message: ''});
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
            }
        );
    }


}
