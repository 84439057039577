import {Component, OnInit} from '@angular/core';
import {HomeStoreService} from '@app/home/store/home-store.service';
import {Observable} from 'rxjs';
import {Home} from '@app/shared/model/home/home';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    home$: Observable<Home>;
    constructor(private hss: HomeStoreService) {
    }

    ngOnInit(): void {
        this.home$ = this.hss.observableValue$;
    }

}
