import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {NewsItem} from '@app/shared/model/vigilance/news-item';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';

@Injectable({
    providedIn: 'root'
})
export class NewsStoreService extends Store<NewsItem[]> {

    constructor(private ds: DataService,
                private ns: NotificatorService) {
        super();
        const news: NewsItem[] = [];
        this.observableValue.next(news);
        this.getNews();
    }

    private getNews() {
        this.loading.next(true);
        this.ds.getNews().subscribe(
            res => {
                if (res.success == true) {
                    this.observableValue.next(res.data);
                } else {
                    this.ns.sendNotification({type: 'error', message: res.error});
                }
                this.loading.next(false);
            },
            error => {
                this.ns.sendNotification({type: 'error', message: error.message});
                this.loading.next(false);
            }
        );
    }
}
