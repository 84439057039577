import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Home} from '@app/shared/model/home/home';
import {HomeStoreService} from '@app/home/store/home-store.service';
import {User} from '@app/shared/model/core/user';
import {CoreStoreService} from '@app/core/store/core-store.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {Core} from '@app/shared/model/core/core';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
    home$: Observable<Home>;
    user$: Observable<User>;
    config$: Observable<Core>;
    showLogin = false;
    loginForm: FormGroup;
    loading$: Observable<boolean>;
    subscription: Subscription;

    constructor(private hss: HomeStoreService,
                private css: CoreStoreService,
                private ns: NotificatorService,
                private fb: FormBuilder) {
    }

    ngOnInit(): void {
        this.home$ = this.hss.observableValue$;
        this.user$ = this.css.observableUser$;
        this.loading$ = this.css.loading$;
        this.config$ = this.css.observableValue$;

        this.loginForm = this.fb.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.subscription = this.ns.getNotification().subscribe(
            notification => {
                switch (notification.type) {
                    case 'login':
                        this.loginForm.reset({username: this.loginForm.value.username, password: ''});
                        this.showLogin = false;
                        break;
                    case 'logout':
                        this.showLogin = false;
                        break;
                }
            }
        );
    }

    onSubmit() {
        this.css.login(this.loginForm.value);
    }

    logout() {
        this.css.logout();
    }

    haveSessionValue(): boolean { // to show my folder
        return this.css.haveSessionValue().username !== '';
    }
}
