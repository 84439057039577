<div class="container mt-2">
    <div class="row mt-2" *ngIf="(loading$ | async) === true">
        <div class="col-12 text-center">
            <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="(loading$ | async) === false">
        <div class="col-1" >
            <button type="button" (click)="goBack()" class="btn btn-outline-info"><i class="fa fa-angle-double-left" aria-hidden="true"></i> regresar</button>
        </div>
        <div class="col-12 col-md-11 text-center">
            <span style="font-size: 1.1em" *ngIf="(article$ | async).title">{{(article$ | async).title}}</span><br>
        </div>
    </div>
    <div class="row" *ngIf="(article$ | async).authors.length > 0">
        <div class="col-12 text-right">
            <span *ngFor="let author of (article$ | async).authors">
                <span class="sci-re-search author-desc">
                    <ng-container *ngIf="(user$|async).username !== ''">
                       <a href="#" routerLink="/metasearcher/sciprofile/{{author.id}}"> {{author.name}}</a>
                    </ng-container>
                    <ng-container *ngIf="(user$|async).username == ''">
                       {{author.name}}
                    </ng-container>
                    <span class="desc">
                        <em>{{author.name}}</em>
                        <small *ngIf="author.email">{{author.email}}</small><br *ngIf="author.email">
                        <small class="sci-re-search" *ngIf="author.affiliation">{{author.affiliation}}</small>
                        <small class="sci-re-search" *ngIf="author.orcid">Orcid: <a href="https://orcid.org/{{author.orcid}}">{{author.orcid}}</a></small>
                    </span>
                </span>&nbsp;&nbsp;
            </span>
        </div>
    </div>
    <div class="row" >
        <div class="col-9">
            <small *ngIf="(article$ | async).journal.publisher">
                <strong>Publisher: </strong>{{(article$ | async).journal.publisher}}<br>
            </small>
            <small *ngIf="(article$ | async).journal.title">
                <strong>Journal Title: </strong>{{(article$ | async).journal.title}}
            </small>&nbsp;&nbsp;

            <small *ngIf="(article$ | async).journal.countryCode">
                <strong>Journal Country: </strong><img alt="Country flag of {{(article$ | async).journal.countryCode}}" [src]="'assets/img/Flags/' + (article$ | async).journal.countryCode + '.PNG'" [title]="(article$ | async).journal.countryCode"><br>
            </small>
            <small *ngIf="(article$ | async).journal.eissn || (article$ | async).journal.pissn">
                <strong>Journal ISSN: </strong>{{(article$ | async).journal.eissn || (article$ | async).journal.pissn}}<br>
            </small>
            <small *ngIf="(article$ | async).volume">
                <strong>Volume: </strong>{{(article$ | async).volume}},
            </small>
            <small *ngIf="(article$ | async).number">
                <strong>Number: </strong>{{(article$ | async).number}}
            </small><br>
            <small *ngIf="(article$ | async).year">
                <strong>Year: </strong>{{(article$ | async).year}}
            </small><br>
            <small *ngIf="(article$ | async).subjects.length > 0">
                <strong>Fuente: </strong>
                <span *ngFor="let s of (article$ | async).sources; let i=index"><span *ngIf="i > 0">, </span><span class="sci-re-search">{{s}}</span></span>
            </small>
        </div>
        <div class="col-3 text-right">
            <a *ngIf="(article$ | async).url" [href]="(article$ | async).url">Texto Completo</a><br>
            <!--            Compartir: <a href="mailto:?subject=Artículo%20en%20{{encodeUri(getCurrentTitle())}}&body={{encodeUri((article$|async).title + '\r\n' + (article$|async).authors[0].name + '\r\n\r\n' + (article$|async).journal.title + '\r\n\r\n' +(article$|async).abstract + '\n\r')}} {{getCurrentUrl()}}"><i class="fas fa-mail-bulk"></i></a>-->
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-right" *ngIf="(loading$ | async) === false">
            <share-buttons [theme]="'modern-dark'"
                           [include]="['telegram','linkedin','twitter','email','print','copy']"
                           [show]="9"
                           [showText]="true"
                           [size]="-4"
                           [title]="(article$|async).title"
                           [description]="(article$|async).abstract"
            ></share-buttons>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-center" *ngIf="(article$ | async).abstract">
            <strong>Abstract</strong>
            <div class="text-justify">
                {{(article$ | async).abstract}}
            </div>
        </div>
        <div class="col-12" *ngIf="(article$ | async).keywords.length > 0">
            <strong>keywords: </strong>
            <span *ngFor="let keyword of (article$ | async).keywords; let i=index"><span *ngIf="i > 0">, </span><span>{{keyword}}</span></span>
        </div>
        <div class="col-12" *ngIf="(article$ | async).subjects.length > 0">
            <strong>subjects: </strong>
            <span *ngFor="let subject of (article$ | async).subjects; let i=index"><span *ngIf="i > 0">, </span><span class="sci-re-search">{{subject.term}}</span></span>
        </div>
        <div class="col-12" *ngIf="(article$ | async).doi">
            <strong>DOI: </strong>
            <span><a href="https://doi.org/{{(article$ | async).doi}}">{{(article$ | async).doi}}</a></span>
        </div>
    </div>
</div>
