import {Component, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Article} from '@app/shared/model/article/article';
import {ArticlesStoreService} from '@app/vigilance/store/articles-store.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NotificatorService} from '@app/shared/notificator/notificator.service';

@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: ['./articles.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(600 )
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(600, style({opacity: 0})))
        ])
    ]
})
export class ArticlesComponent implements OnInit {
    articles$: Observable<Article[]>;
    loading$: Observable<any>;
    count$: Observable<number>;
    subscription: Subscription;

    constructor(private ass: ArticlesStoreService,
                private ns: NotificatorService) {
    }

    ngOnInit(): void {
        this.articles$ = this.ass.observableValue$;
        this.loading$ = this.ass.loading$;
        this.count$ = this.ass.count$;

        this.subscription = this.ns.getNotification().subscribe(
            notification => {
                switch (notification.type) {
                    case 'login':
                    case 'logout':
                        this.ass.getLastArticles();
                        this.ass.getLastArticlesCount();
                        break;
                }
            }
        );
    }

}
