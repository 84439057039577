import {Injectable} from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class MapBuilderService {

    constructor() {
    }

    static loadMap(graph: any, gravity: number, charge: number, fontSize: number, showLabels: boolean) {
        const width = 1000;
        const height = 620;
        const maxWeight = _.head(graph.nodes.map(x => x.weight).sort((a, b) => b - a));
        const minWeight = _.last(graph.nodes.map(x => x.weight).sort((a, b) => b - a));
        const maxValue = _.head(graph.links.map(x => x.value).sort((a, b) => b - a));
        const minValue = _.last(graph.links.map(x => x.value).sort((a, b) => b - a));

        d3.select(`#legend1`).html(`${minWeight}/${maxWeight}`);
        d3.select(`#legend2`).html(`${minValue}/${maxValue}`);
        d3.select(`#legend3`).html(`${graph.nodes.length}`);
        d3.select(`#legend4`).html(`${graph.links.length}`);


        const svg = d3.select(`#mapa`)
            .html('')
            .style('background-color', '#ffffe5')
            .style('border', '1px solid #8a6d3b')
            .attr('viewBox', `0 0 ${width} ${height}`)
            .attr('preserveAspectRatio', 'xMinYMin')
            .style('pointer-events', 'all');

        const mw = (maxWeight === minWeight) ? 1 : (18 - 6) / (maxWeight - minWeight);
        const nw = (maxWeight === minWeight) ? 6 : 18 - (mw * maxWeight);

        const forceX = d3.forceX(width / 2).strength(0.15);
        const forceY = d3.forceY(height / 2).strength(0.15);

        const simulation = d3.forceSimulation()
            .force('link', d3.forceLink()
                .id(d => d.id)
                .distance(d => Math.sqrt((width * height) / graph.nodes.length))
                .strength(gravity)
            )
            .force('charge', d3.forceManyBody().strength(-charge))
            .force('collide', d3.forceCollide().radius(d => mw * d.value + nw))
            .force('center', d3.forceCenter(width / 2, height / 2))
            .force('x', forceX)
            .force('y', forceY);


        const mv = (10 - 3) / (maxValue - minValue);
        const nv = 10 - (mv * maxValue);
        const link = svg.append('g')
            .selectAll('line')
            .data(graph.links)
            .enter().append('g')
            .attr('class', 'link')
            .append('line')
            .join('line')
            .attr('stroke', d => {
                if (d.value === maxValue) {
                    return '#111'; // #111
                } else if (d.value === minValue) {
                    return '#999'; // #999
                } else {
                    return '#555'; // #555
                }
            })
            .attr('stroke-opacity', 0.6)
            .attr('stroke-width', d => Math.ceil(mv * d.value + nv));
        link.append('title')
            .text(d => d.value);

        const linkText = svg.selectAll('.link')
            .append('text')
            .attr('display', 'none')
            .attr('font-family', 'Arial, Helvetica, sans-serif')
            .attr('fill', 'Black')
            .style('font', 'normal 16px Arial')
            .attr('dy', '.35em')
            .text(function (d) {
                return d.value;
            });

        // Toggle stores whether the highlighting is on
        let toggle = false;
        // Create an array logging what is connected to what
        const linkedByIndex = {};
        for (let i = 0; i < graph.nodes.length; i++) {
            linkedByIndex[i + ',' + i] = 1;
        }
        graph.links.forEach(d => linkedByIndex[d.source + ',' + d.target] = 1);

        const node = svg.append('g')
            .attr('class', 'nodes')
            .selectAll('g')
            .data(graph.nodes)
            .enter().append('g');

        node.call(this.drag(simulation))
            .on('dblclick', function () {
                if (toggle === false) {
                    // Reduce the opacity of all but the neighbouring nodes
                    const d = d3.select(this).node().__data__;
                    node.style('opacity', o => linkedByIndex[d.index + ',' + o.index] || linkedByIndex[o.index + ',' + d.index] ? 1 : 0.3);
                    link.style('opacity', o => d.index === o.source.index || d.index === o.target.index ? 1 : 0.1);
                    svg.selectAll('.link').select('text').attr('display',o => d.index === o.source.index || d.index === o.target.index ? '' : 'none');
                    if (!showLabels) {
                        node.select('text').attr('display', o => linkedByIndex[d.index + ',' + o.index] || linkedByIndex[o.index + ',' + d.index] ? '' : 'none');
                    }

                    // Reduce the op
                    toggle = true;
                } else {
                    // Put them back to opacity=1
                    node.style('opacity', 1);
                    link.style('opacity', 1);
                    svg.selectAll('.link').select('text').attr('display','none');
                    if (!showLabels) {
                        node.select('text').attr('display', 'none');
                    }
                    toggle = false;
                }
            });

        node.append('circle')
            .attr('stroke', '#ccc')
            .attr('stroke-width', 2)
            .attr('r', d => Math.ceil(mw * d.weight + nw))
            .attr('fill', d => `#${d.group}`);


        node.append('text')
            .attr('dx', d => Math.ceil(mw * d.weight + nw) + 3)
            .attr('dy', '.35em')
            .style('font', fontSize + 'px sans-serif')
            .style('fill', '#000') // #AAAAAA
            .text(d => d.name);
        if (!showLabels) {
            node.select('text').attr('display', 'none');
        }

        simulation
            .nodes(graph.nodes);

        simulation.force('link')
            .links(graph.links);

        simulation.on('tick', () => {
            link
                .attr('x1', d => d.source.x)
                .attr('y1', d => d.source.y)
                .attr('x2', d => d.target.x)
                .attr('y2', d => d.target.y);
            node.attr('transform', (d) => {
                d.x = Math.max(18, Math.min(width - 18, d.x));
                d.y = Math.max(18, Math.min(height - 18, d.y));
                return `translate(${d.x}, ${d.y})`;
            });
            linkText
                .attr('x', function (d) {
                    return ((d.source.x+10 + d.target.x) / 2);
                })
                .attr('y', function (d) {
                    return ((d.source.y + d.target.y) / 2);
                });
        });

        simulation.on('end', () => {
            // para que despues de terminar la simulación todos los nodos se queden fijos
            node.attr('transform', (d) => {
                d.fx = Math.max(18, Math.min(width - 18, d.x));
                d.fy = Math.max(18, Math.min(height - 18, d.y));
                return `translate(${d.fx}, ${d.fy})`;
            });
        });

        node.on('mouseover', (d) => {
            link.attr('stroke-opacity', (o) => {
                return (o.source === d || o.target === d) ? 1 : 0.6;
            });
            if (!showLabels) {
                // node.select('text').attr('display', o => (d == o) ? '' : 'none');
            }
        });

        node.on('mouseout', () => {
            link.attr('stroke-opacity', 0.6);
            if (!showLabels) {
                // node.select('text').attr('display', 'none');
            }
        });

    }

    private static drag = simulation => {

        function dragstarted(d) {
            if (!d3.event.active) {
                simulation.alphaTarget(0.3).restart();
            }
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(d) {
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        function dragended(d) {
            if (!d3.event.active) {
                simulation.alphaTarget(0);
            }
            d.fx = d3.event.x;
            d.fy = d3.event.y;
        }

        return d3.drag()
            .on('start', dragstarted)
            .on('drag', dragged)
            .on('end', dragended);
    };
}
