<div class="container-fluid">
    <div class="row mt-4">
        <div class="col">
            <ul class="nav nav-tabs">
                <li class="nav-item" placement="top-left" ngbTooltip="Indicadores que miden frecuencia">
                    <a class="nav-link" routerLink="frequency" routerLinkActive="active">Frecuencia</a>
                </li>
                <li class="nav-item" placement="top" ngbTooltip="Indicadores que usan algoritmo relacional para su representación">
                    <a class="nav-link" routerLink="map" routerLinkActive="active">Relacionales</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
