<div class="container-fluid">
    <div class="row d-flex justify-content-between">
        <div class="col-8 offset-2 text-center">
            <img src="assets/img/banner-short.png" class="img-fluid" alt="">
        </div>
    </div>
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row d-flex align-items-center justify-content-around pt-2">
        <div class="col-10 col-sm-7 col-md-5 col-lg-3 text-center px-2 mt-2" routerLink="//metasearcher" [@simpleFadeAnimation]="'in'">
            <div class="card shadow" style="cursor: pointer">
                <div class="card-header">
                    <h4 class="card-title my-0" style="color: {{(home$|async).toolsNameColor}}"><strong>{{(home$|async).metasearcher.name}}</strong></h4>
                </div>
                <div class="card-body px-5 mx-5">
                    <img src="assets/img/{{(home$|async).metasearcher.image}}" class="card-img-top" alt="">
                </div>
                <div class="card-footer py-1">
                    <div class="row">
                        <div class="col-12">{{(home$|async).metasearcher.description}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-10 col-sm-7 col-md-5 col-lg-3 text-center px-2 mt-2" routerLink="//metrics" [@simpleFadeAnimation]="'in'">
            <div class="card shadow" style="cursor: pointer">
                <div class="card-header">
                    <h4 class="card-title my-0" style="color: {{(home$|async).toolsNameColor}}"><strong>{{(home$|async).metrics.name}}</strong></h4>
                </div>
                <div class="card-body px-5 mx-5">
                    <img src="assets/img/{{(home$|async).metrics.image}}" class="card-img-top" alt="">
                </div>
                <div class="card-footer py-1">
                    <div class="row">
                        <div class="col-12">{{(home$|async).metrics.description}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-10 col-sm-7 col-md-5 col-lg-3 text-center px-2 mt-2" routerLink="//vigilance" [@simpleFadeAnimation]="'in'">
            <div class="card shadow" style="cursor: pointer">
                <div class="card-header">
                    <h4 class="card-title my-0" style="color: {{(home$|async).toolsNameColor}}"><strong>{{(home$|async).vigilance.name}}</strong></h4>
                </div>
                <div class="card-body px-5 mx-5">
                    <img src="assets/img/{{(home$|async).vigilance.image}}" class="card-img-top" alt="">
                </div>
                <div class="card-footer py-1">
                    <div class="row">
                        <div class="col-12">{{(home$|async).vigilance.description}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-10 col-sm-7 col-md-5 col-lg-3 text-center px-2 mt-2" [@simpleFadeAnimation]="'in'" *ngIf="(home$ | async).extTool.activated">
            <div class="card shadow" style="position: relative;cursor: pointer" (click)="goExtTool()">
                <div class="card-header">
                    <h4 class="card-title my-0" style="color: {{(home$|async).toolsNameColor}}"><strong>{{(home$ | async).extTool.name}}</strong></h4>
                </div>
                <div class="card-body px-5 mx-5">
                    <img src="assets/img/{{(home$ | async).extTool.image}}" class="card-img-top" alt="">
                </div>
                <div class="card-footer py-1">
                    <div class="row">
                        <div class="col-12 text-decoration-none">{{(home$ | async).extTool.description}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
