import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {PersonProfile} from '@app/shared/model/profile/person-profile';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileStoreService extends Store<PersonProfile> {

    constructor(private ds: DataService,
                private ns: NotificatorService) {
        super();
        this.init();
    }

    private init() {
        const personProfile: PersonProfile = {
            id: null,
            name: '',
            countryCode: '',
            countryName: '',
            assignees: {labels: [], datasets: []},
            inventors: {labels: [], datasets: []},
            cpcs: {labels: [], datasets: []},
            ipcs: {labels: [], datasets: []},
            patents: [],
            affiliation: '',
            email: '',
            orcid: '',
            articles: [],
            authors: {labels: [], datasets: []},
            kwrds: {labels: [], datasets: []},
            MeSHs: {labels: [], datasets: []}
        };
        this.observableValue.next(personProfile);
    }

    getAssigneeProfile(id: number) {
        if (id !== this.observableValue.getValue().id) {
            this.init();
            this.loading.next(true);
            this.ds.getAssigneeProfile(id).subscribe(
                res => {
                    if (res.success == true) {
                        res.data.assignees.datasets[0].borderWidth = 1;
                        res.data.assignees.datasets[0].borderColor = `rgba(255, 99, 132, 1)`;
                        res.data.assignees.datasets[0].backgroundColor = `rgba(255, 99, 132, 0.2)`;
                        res.data.inventors.datasets[0].borderWidth = 1;
                        res.data.inventors.datasets[0].borderColor = `rgba(54, 162, 235, 1)`;
                        res.data.inventors.datasets[0].backgroundColor = `rgba(54, 162, 235, 0.2)`;
                        res.data.ipcs.datasets[0].borderWidth = 1;
                        res.data.ipcs.datasets[0].borderColor = `rgba(75, 192, 75, 1)`;
                        res.data.ipcs.datasets[0].backgroundColor = `rgba(75, 192, 75, 0.2)`;
                        res.data.cpcs.datasets[0].borderWidth = 1;
                        res.data.cpcs.datasets[0].borderColor = `rgba(152, 118, 170, 1)`;
                        res.data.cpcs.datasets[0].backgroundColor = `rgba(152, 118, 170, 0.2)`;
                        this.observableValue.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'error', message: res.error});
                    }
                    this.loading.next(false);
                },
                error => {
                    this.ns.sendNotification({type: 'error', message: error.message});
                    this.loading.next(false);
                }
            );
        }
    }

    getInventorProfile(id: number) {
        if (id !== this.observableValue.getValue().id) {
            this.init();
            this.loading.next(true);
            this.ds.getInventorProfile(id).subscribe(
                res => {
                    if (res.success == true) {
                        res.data.assignees.datasets[0].borderWidth = 1;
                        res.data.assignees.datasets[0].borderColor = `rgba(255, 99, 132, 1)`;
                        res.data.assignees.datasets[0].backgroundColor = `rgba(255, 99, 132, 0.2)`;
                        res.data.inventors.datasets[0].borderWidth = 1;
                        res.data.inventors.datasets[0].borderColor = `rgba(54, 162, 235, 1)`;
                        res.data.inventors.datasets[0].backgroundColor = `rgba(54, 162, 235, 0.2)`;
                        res.data.ipcs.datasets[0].borderWidth = 1;
                        res.data.ipcs.datasets[0].borderColor = `rgba(75, 192, 75, 1)`;
                        res.data.ipcs.datasets[0].backgroundColor = `rgba(75, 192, 75, 0.2)`;
                        res.data.cpcs.datasets[0].borderWidth = 1;
                        res.data.cpcs.datasets[0].borderColor = `rgba(152, 118, 170, 1)`;
                        res.data.cpcs.datasets[0].backgroundColor = `rgba(152, 118, 170, 0.2)`;
                        this.observableValue.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'error', message: res.error});
                    }
                    this.loading.next(false);
                },
                error => {
                    this.ns.sendNotification({type: 'error', message: error.message});
                    this.loading.next(false);
                }
            );
        }
    }

    getAuthorProfile(id: number) {
        if (id !== this.observableValue.getValue().id) {
            this.init();
            this.loading.next(true);
            this.ds.getAuthorProfile(id).subscribe(
                res => {
                    if (res.success == true) {
                        res.data.authors.datasets[0].borderWidth=1;
                        res.data.authors.datasets[0].borderColor = `rgba(255, 99, 132, 1)`;
                        res.data.authors.datasets[0].backgroundColor = `rgba(255, 99, 132, 0.2)`;
                        res.data.kwrds.datasets[0].borderWidth=1;
                        res.data.kwrds.datasets[0].borderColor = `rgba(54, 162, 235, 1)`;
                        res.data.kwrds.datasets[0].backgroundColor = `rgba(54, 162, 235, 0.2)`;
                        res.data.MeSHs.datasets[0].borderWidth=1;
                        res.data.MeSHs.datasets[0].borderColor = `rgba(75, 192, 75, 1)`;
                        res.data.MeSHs.datasets[0].backgroundColor = `rgba(75, 192, 75, 0.2)`;
                        this.observableValue.next(res.data);
                    } else {
                        this.ns.sendNotification({type: 'error', message: res.error});
                    }
                    this.loading.next(false);
                },
                error => {
                    this.ns.sendNotification({type: 'error', message: error.message});
                    this.loading.next(false);
                }
            );
        }
    }
}
