import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {Subscription} from 'rxjs';
import {SwUpdate} from '@angular/service-worker';


@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    showConfirm = false;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private toastr: ToastrService,
                private ns: NotificatorService,
                private swUpdate: SwUpdate) {
    }

    ngOnInit() {
        this.subscription = this.ns.getNotification().subscribe(
            notification => {
                switch (notification.type) {
                    case 'success':
                        setTimeout(() => this.toastr.success(notification.message, 'OK!'));
                        break;
                    case 'error':
                        setTimeout(() => this.toastr.error(notification.message, 'Ups!'));
                        break;
                    case 'warning':
                        setTimeout(() => this.toastr.warning(notification.message, 'Alerta!'));
                        break;
                    case 'info':
                        setTimeout(() => this.toastr.info(notification.message));
                        break;
                }
            }
        );

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                this.showConfirm = true;
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onActivate(event: any) {
        if (isPlatformBrowser(this.platformId)) {
            const pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, 0);
            }
        }
    }

    install() {
        window.location.reload();
    }
}
