import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ArticleChunk} from '@app/shared/model/search/article-chunk';
import {PatentChunk} from '@app/shared/model/search/patent-chunk';
import {ArticleFolderStoreService} from '@app/shared/folder/article-folder-store.service';
import {PatentFolderStoreService} from '@app/shared/folder/patent-folder-store.service';

@Component({
    selector: 'app-folder',
    templateUrl: './folder.component.html',
    styleUrls: ['./folder.component.scss']
})
export class FolderComponent implements OnInit {
    articleFolder$: Observable<ArticleChunk[]>;
    patentFolder$: Observable<PatentChunk[]>;

    constructor(private afss: ArticleFolderStoreService,
                private pfss: PatentFolderStoreService) {
    }

    ngOnInit(): void {
        this.articleFolder$ = this.afss.observableValue$;
        this.patentFolder$ = this.pfss.observableValue$;
    }

    removeArticle(e: ArticleChunk) {
        this.afss.deleteFromFolder(e);
    }

    removePatent(e: PatentChunk) {
        this.pfss.deleteFromFolder(e);
    }
}
