import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {WhoWeAre} from '@app/shared/model/who-we-are/who-we-are';
import {WhoWeAreStoreService} from '@app/who-we-are/store/who-we-are-store.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-who-we-are',
    templateUrl: './who-we-are.component.html',
    styleUrls: ['./who-we-are.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({opacity: 1})),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({opacity: 0}),
                animate(600 )
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(600, style({opacity: 0})))
        ])
    ]
})
export class WhoWeAreComponent implements OnInit {
    whoweare$: Observable<WhoWeAre>;
    loading$: Observable<boolean>

    constructor(private wss: WhoWeAreStoreService) {
    }

    ngOnInit() {
        this.whoweare$ = this.wss.observableValue$;
        this.loading$ = this.wss.loading$;
    }
}
