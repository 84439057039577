<div class="container-fluid">
    <div class="row d-flex justify-content-center">
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 m-1">
            <div class="card">
                <h4 class="card-header">404 No se encuentra</h4>
                <div class="card-block mx-2">
                    <p>La página que buscas no existe</p>
                    <p><a href="#" routerLink="/">Inicio</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
