<table class="table table-bordered table-sm table-striped">
    <thead>
    <tr>
        <th>Símbolo</th>
        <th>Descripción</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let c of legend">
        <td>
            <span class="badge">{{c.symbol}}</span>
        </td>
        <td>
            <ng-container *ngFor="let d of c.description">
                <span [ngClass]="colorize(d.level)">{{d.title}}<sub class="badge text-dark">{{d.symbol}}</sub>  </span>
            </ng-container>
        </td>
    </tr>
    </tbody>
</table>
