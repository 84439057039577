<div class="container">
    <div class="row mt-2" style="position: relative">
        <div class="col-12 text-center" style="position: absolute; top: 0; z-index: 999">
            <div *ngIf="(loading$|async)===true" class="spinner-border text-success" style="width: 3rem; height: 3rem;"
                 role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-between">
        <div class="col-8 offset-2 text-center">
            <img src="assets/img/banner-short.png" class="img-fluid" alt="">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h3>Acerca de:</h3>
        </div>
    </div>
    <div class="row" [@simpleFadeAnimation]="'in'">
        <div class="col-12 text-justify" *ngFor="let p of (about$|async).firsts">
            <p>{{p}}</p>
        </div>
        <div class="col-12">
            <ul>
                <li class="text-justify" *ngFor="let item of (about$|async).items">
                    <strong>{{item.name}}:</strong> {{item.description}}
                </li>
            </ul>
        </div>
        <div class="col-12 text-justify" *ngFor="let p of (about$|async).lasts">
            <p>{{p}}</p>
        </div>
    </div>
</div>
