import {Component, HostListener, OnInit} from '@angular/core';
import {SearchResult} from '@app/shared/model/search/search-result';
import {Observable} from 'rxjs';
import {MetaSearcherStoreService} from '@app/meta-searcher/store/meta-searcher-store.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Home} from '@app/shared/model/home/home';
import {HomeStoreService} from '@app/home/store/home-store.service';
import {ArticleFolderStoreService} from '@app/shared/folder/article-folder-store.service';
import {PatentFolderStoreService} from '@app/shared/folder/patent-folder-store.service';
import {ArticleChunk} from '@app/shared/model/search/article-chunk';
import {CoreStoreService} from '@app/core/store/core-store.service';
import {PatentChunk} from '@app/shared/model/search/patent-chunk';

@Component({
    selector: 'app-meta-searcher',
    templateUrl: './meta-searcher.component.html',
    styleUrls: ['./meta-searcher.component.scss']
})
export class MetaSearcherComponent implements OnInit {
    searchResult$: Observable<SearchResult>;
    loading$: Observable<boolean>;
    searchForm: FormGroup;
    subjects$: Observable<string[]>;
    home$: Observable<Home>;

    constructor(private fb: FormBuilder,
                private mss: MetaSearcherStoreService,
                private hss: HomeStoreService,
                private afss: ArticleFolderStoreService,
                private pfss: PatentFolderStoreService,
                private css: CoreStoreService) {
    }


    ngOnInit(): void {
        this.searchResult$ = this.mss.observableValue$;
        this.subjects$ = this.mss.oobservableSubject$;
        this.loading$ = this.mss.loading$;
        this.home$ = this.hss.observableValue$;
        this.mss.observableForm$.subscribe(
            res => {
                this.searchForm = this.fb.group({
                    filter: [res.filter, [Validators.required]],
                    authorFilter: [res.authorFilter],
                    yearFilter: [res.yearFilter],
                    indexname: [res.indexname, [Validators.required]],
                    subject: [res.subject, [Validators.required]],
                    pageStart: [res.pageStart, [Validators.required]]
                });
            }
        );
    }

    onSubmit(): void {
        this.searchForm.patchValue({pageStart: 0});
        this.mss.search(this.searchForm.value);
    }

    haveSessionValue(): boolean {
        return this.css.haveSessionValue().username !== '';
    }

    addArticle(a: ArticleChunk) {
        if (this.afss.addToFolder(a)) {
            a.inUserFolder = true;
        }
    }

    removeArticle(a: ArticleChunk) {
        if (this.afss.deleteFromFolder(a)) {
            a.inUserFolder = false;
        }
    }

    addPatent(p: PatentChunk) {
        if (this.pfss.addToFolder(p)) {
            p.inUserFolder = true;
        }
    }

    removePatent(p: PatentChunk) {
        if (this.pfss.deleteFromFolder(p)) {
            p.inUserFolder = false;
        }
    }


    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        let pos = Math.ceil(document.documentElement.scrollTop || document.body.scrollTop || 0);
        let max = document.body.scrollHeight - window.innerHeight;
        console.log(`${pos} -> ${max}`);
        if (pos >= max && !this.mss.getLoadingValue() && this.mss.getResultCount() > 30) {
            const pageStart = this.searchForm.value.pageStart + 1;
            this.searchForm.patchValue({pageStart: pageStart});
            this.mss.search(this.searchForm.value);
        }
    }
}
