import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafePipe} from './pipes/safe.pipe';
import { ChartCardComponent } from './chart-card/chart-card.component';
import {ChartModule} from 'angular2-chartjs';


@NgModule({
    declarations: [SafePipe, ChartCardComponent],
    imports: [
        CommonModule,
        ChartModule
    ],
    exports: [
        SafePipe,
        ChartCardComponent
    ]
})
export class SharedModule {
}
