<div class="container mt-2">
    <div class="row mt-2" *ngIf="(loading$ | async) === true">
        <div class="col-12 text-center">
            <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="(loading$ | async) === false">
        <div class="col-1" >
            <button type="button" (click)="goBack()" class="btn btn-outline-info"><i class="fa fa-angle-double-left" aria-hidden="true"></i> regresar</button>
        </div>
        <div class="col-12 col-md-11 text-center">
            <h2>{{(personProfile$| async).name}}</h2>
            <h5 class="d-flex d-inline-flex">{{(personProfile$| async).email}}</h5>
            <h5 class="d-flex d-inline-flex">{{(personProfile$| async).orcid}}</h5>
            <h5 class="d-flex d-inline-flex">{{(personProfile$| async).affiliation}}</h5>
        </div>
    </div>
    <div class="row mt-2"  *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>Vinculación Científica</h4>
        </div>
        <div class="col-12">
            <app-chart-card [data]="(personProfile$|async).authors" type="horizontalBar"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2"  *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>Dominio Temático</h4>
        </div>
        <div class="col-12">
            <app-chart-card [data]="(personProfile$|async).kwrds" type="horizontalBar"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2"  *ngIf="(loading$ | async) === false && (personProfile$|async).MeSHs.labels.length > 0">
        <div class="col-12 text-center">
            <h4>Asignación Temática - MeSH</h4>
        </div>
        <div class="col-12">
            <app-chart-card [data]="(personProfile$|async).MeSHs" type="horizontalBar"></app-chart-card>
        </div>
    </div>
    <div class="row mt-2"  *ngIf="(loading$ | async) === false">
        <div class="col-12 text-center">
            <h4>Artículos</h4>
        </div>
        <div class="col-12" *ngFor="let e of (personProfile$ | async).articles">
            <a href="#" routerLink="/metasearcher/article/{{e.id}}">{{e.year}}-{{e.month}} {{e.title}}</a><br><strong>Revista:</strong> {{e.journal.title}}<br>
        </div>
    </div>
</div>


