import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Article} from '@app/shared/model/article/article';
import {ArticleStoreService} from '@app/meta-searcher/store/article-store.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {EncodeUriService} from '@app/shared/encode-uri/encode-uri.service';
import {Title} from '@angular/platform-browser';
import {User} from '@app/shared/model/core/user';
import {CoreStoreService} from '@app/core/store/core-store.service';

@Component({
    selector: 'app-article-view',
    templateUrl: './article-view.component.html',
    styleUrls: ['./article-view.component.scss']
})
export class ArticleViewComponent implements OnInit, OnDestroy {
    article$: Observable<Article>;
    loading$: Observable<boolean>;
    user$: Observable<User>;
    id = 0;

    constructor(private location: Location,
                private route: ActivatedRoute,
                private ass: ArticleStoreService,
                private ts: Title,
                private css: CoreStoreService) {
        route.params.subscribe(params => {
            this.id = parseInt(params.id);
        });
    }

    ngOnInit(): void {
        this.article$ = this.ass.observableValue$;
        this.loading$ = this.ass.loading$;
        this.user$ = this.css.observableUser$;
        this.ass.getArticle(this.id);
    }

    goBack(): void {
        this.location.back();
    }

    ngOnDestroy(): void {
        this.ass.removeMetaTags();
    }

    encodeUri(text): string {
        return EncodeUriService.encodeUriComponent(text);
    }

    getCurrentUrl(): string {
        return window.location.href;
    }

    getCurrentTitle(): string {
        return this.ts.getTitle();
    }
}
