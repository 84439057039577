import {Injectable} from '@angular/core';
import {Store} from '@app/shared/store/store';
import {Article} from '@app/shared/model/article/article';
import {DataService} from '@app/shared/data/data.service';
import {NotificatorService} from '@app/shared/notificator/notificator.service';
import {Meta} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class ArticleStoreService extends Store<Article> {

    constructor(private ds: DataService,
                private ns: NotificatorService,
                private meta: Meta) {
        super();
        this.init();
    }

    private init() {
        const article: Article = {
            abstract: '',
            journal: {
                title: '',
                countryCode: '',
                eissn: '',
                pissn: '',
                publisher: ''
            },
            month: 0,
            authors: [],
            title: '',
            doaj_id: '',
            doi: '',
            end_page: 0,
            id: 0,
            keywords: [],
            mesh: [],
            number: 0,
            sources: [],
            start_page: 0,
            subjects: [],
            url: '',
            volume: 0,
            year: 0
        };
        this.observableValue.next(article);
    }

    getArticle(id: number) {
        if (id !== this.observableValue.getValue().id) {
            this.init();
            this.loading.next(true);
            this.ds.getArticle(id).subscribe(
                res => {
                    if (res.success == true) {
                        this.observableValue.next(res.data);
                        this.addMetaTags(res.data);
                    } else {
                        this.ns.sendNotification({type: 'error', message: res.error});
                    }
                    this.loading.next(false);
                },
                error => {
                    this.ns.sendNotification({type: 'error', message: error.message});
                    this.loading.next(false);
                }
            );
        }
    }

    addMetaTags(article: Article) {
        article.authors.map(a => {
            this.meta.addTag({name: 'DC.Creator.PersonalName', content: a.name});
            this.meta.addTag({name: 'citation_author', content: a.name});
        });
        this.meta.addTag({name: 'DC.Date.issued', content: `${article.year}`, scheme: 'ISO8601'});
        this.meta.addTag({name: 'citation_date', content: `${article.year}`, scheme: 'ISO8601'});

        this.meta.addTag({name: 'DC.Format', content: 'text/html', scheme: 'IMT'});

        this.meta.addTag({name: 'DC.Description', content: article.abstract});

        this.meta.addTag({name: 'DC.Identifier', content: article.doi, scheme: 'doi'});
        this.meta.addTag({name: 'DC.Identifier.URI', content: article.url});
        this.meta.addTag({name: 'citation_fulltext_html_url', content: article.url});

        this.meta.addTag({name: 'DC.Identifier.pageNumber', content: `${article.start_page}-${article.end_page}`});
        this.meta.addTag({name: 'citation_firstpage', content: `${article.start_page}`});
        this.meta.addTag({name: 'citation_lastpage', content: `${article.end_page}`});

        this.meta.addTag({name: 'DC.Source', content: article.journal.title});
        this.meta.addTag({name: 'citation_journal_title', content: article.journal.title});

        this.meta.addTag({name: 'DC.Source.ISSN', content: article.journal.eissn || article.journal.pissn});
        this.meta.addTag({name: 'citation_issn', content: article.journal.eissn || article.journal.pissn});

        this.meta.addTag({name: 'DC.Source.Issue', content: `${article.number}`});
        this.meta.addTag({name: 'citation_issue', content: `${article.number}`});

        this.meta.addTag({name: 'DC.Source.Volume', content: `${article.volume}`});
        this.meta.addTag({name: 'citation_volume', content: `${article.volume}`});

        this.meta.addTag({name: 'DC.Source.URI', content: window.location.href});
        this.meta.addTag({name: 'citation_pdf_url', content: article.url});
        article.subjects.map(s => {
            this.meta.addTag({name: 'DC.Subject', content: s.term});
        });
        this.meta.addTag({name: 'DC.Title', content: article.title});
        this.meta.addTag({name: 'citation_title', content: article.title});

        this.meta.addTag({name: 'DC.Type', content: 'Text.Serial.Journal'});
        document.dispatchEvent(new Event('ZoteroItemUpdated', {
            bubbles: true,
            cancelable: true
        }));
    }

    removeMetaTags(): void {
        const article = this.observableValue.getValue();

        article.authors.map(() => {
            this.meta.removeTag('name="DC.Creator.PersonalName"');
            this.meta.removeTag('name="citation_author"');
        });
        this.meta.removeTag('name="DC.Date.issued"');
        this.meta.removeTag('name="citation_date"');

        this.meta.removeTag('name="DC.Format"');

        this.meta.removeTag('name="DC.Description"');

        this.meta.removeTag('name="DC.Identifier"');
        this.meta.removeTag('name="DC.Identifier.URI"');
        this.meta.removeTag('name="citation_fulltext_html_url"');

        this.meta.removeTag('name="DC.Identifier.pageNumber"');
        this.meta.removeTag('name="citation_firstpage"');
        this.meta.removeTag('name="citation_lastpage"');

        this.meta.removeTag('name="DC.Source"');
        this.meta.removeTag('name="citation_journal_title"');

        this.meta.removeTag('name="DC.Source.ISSN"');
        this.meta.removeTag('name="citation_issn"');

        this.meta.removeTag('name="DC.Source.Issue"');
        this.meta.removeTag('name="citation_issue"');

        this.meta.removeTag('name="DC.Source.Volume"');
        this.meta.removeTag('name="citation_volume"');

        this.meta.removeTag('name="DC.Source.URI"');
        this.meta.removeTag('name="citation_pdf_url"');
        article.subjects.map(() => {
            this.meta.removeTag('name="DC.Subject"');
        });
        this.meta.removeTag('name="DC.Title"');
        this.meta.removeTag('name="citation_title"');

        this.meta.removeTag('name="DC.Type"');
        document.dispatchEvent(new Event('ZoteroItemUpdated', {
            bubbles: true,
            cancelable: true
        }));
    }
}
