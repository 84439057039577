import {Component, Input, OnInit} from '@angular/core';
import {FrequencyChart} from '@app/shared/model/metrics/frequency-chart';

@Component({
    selector: 'app-chart-card',
    templateUrl: './chart-card.component.html',
    styleUrls: ['./chart-card.component.scss']
})
export class ChartCardComponent implements OnInit {
    @Input() indcode: string = '';
    @Input() data: FrequencyChart;
    @Input() type: string;
    showGraph = true;
    options = {
        maintainAspectRatio: true,
        responsive: true,
        title: {
            display: false
        },
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        },
        scales: {
            xAxes: [{
                ticks: {
                    beginAtZero: true,
                    fontColor: '#000000', // 222222
                    fontFamily: '\'Roboto\', sans-serif',
                    fontSize: 16
                }
            }],
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    fontSize: 16,
                    fontFamily: '\'Roboto\', sans-serif',
                    fontColor: '#000000' // 222222
                }
            }]
        },
        hover: {
            animationDuration: 0
        },
        animation: {
            duration: 1500,
            easing: 'easeOutQuart',
            onComplete: function () {
                const ctx = this.chart.ctx;
                //this.chart.canvas.parentNode.style.width = '4280px';
                const type = this.chart.config.type;
                this.data.datasets.forEach(function (dataset) {
                    for (let i = 0; i < dataset.data.length; i++) {
                        const model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                        let scale_max = 0;
                        let y_pos = 0;
                        let x_pos = 0;
                        switch (type) {
                            case 'line':
                            case 'bar':
                                scale_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                                ctx.fillStyle = '#000'; // 777
                                y_pos = model.y - 5;
                                if ((scale_max - model.y) / scale_max >= 0.93) {
                                    y_pos = model.y + 20;
                                }
                                x_pos = model.x - dataset.data[i].toString().length / 2 * 10;
                                scale_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._xScale.right;
                                if (model.x / scale_max <= 0.10) {
                                    x_pos = model.x;
                                }
                                ctx.fillText(dataset.data[i].toString(), x_pos, y_pos);
                                break;
                            case 'horizontalBar':
                                scale_max = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._xScale.right;
                                ctx.fillStyle = '#000'; // 777
                                x_pos = model.x + dataset.data[i].toString().length / 2 * 10;
                                const x_pos_lenght = model.x + dataset.data[i].toString().length * 10;
                                if (x_pos_lenght / scale_max >= 1) {
                                    x_pos = model.x - dataset.data[i].toString().length / 2 * 10;
                                }
                                ctx.fillText(dataset.data[i].toString(), x_pos, model.y + 8);
                                break;
                        }
                    }
                });
            }
        }
    };

    constructor() {
    }

    ngOnInit(): void {
    }

    getSum() {
        return this.data.datasets[0].data.reduce((a, b) => a + b, 0);
    }
}
