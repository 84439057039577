<div class="row mt-1">
    <div class="col-12">
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" href="#" [ngClass]="showGraph === true?'active':''" (click)="$event.preventDefault();showGraph=true;">Gráfico</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#" [ngClass]="showGraph === false?'active':''" (click)="$event.preventDefault();showGraph=false;">Tabla de datos</a>
            </li>
        </ul>
    </div>
</div>
<div class="row mt-2" [ngClass]="(showGraph==true)?'d-none':'d-block'" *ngIf="data.labels.length > 0">
    <div class="col-12">
        <table class="table table-bordered table-sm table-striped shadow">
            <tr class="table-success">
                <th class="text-center">Etiqueta</th>
                <th class="text-center">Cantidad</th>
            </tr>
            <tr *ngFor="let r of data.labels; let i = index;">
                <td>{{r}}</td>
                <td>{{data.datasets[0].data[i]}}</td>
            </tr>
            <tr class="table-active">
                <td>Total:</td>
                <td>{{getSum()}}</td>
            </tr>
        </table>
    </div>
</div>
<div class="row mt-2" [ngClass]="(showGraph==true)?'d-block':'d-none'" *ngIf="data.labels.length > 0">
    <div class="col-12">
        <div class="card shadow">
            <div class="card-body">
                <chart [data]="data" [type]="type" [options]="options"></chart>
            </div>
            <div class="card-footer" *ngIf="indcode !== 'ART_CLxAN'">
                Total: {{getSum()}}
            </div>
        </div>
    </div>
</div>
